import React, { useState } from 'react';
import PropTypes from 'prop-types';

import ViewFadeGroup from '../../../components/Fade/FadeGroup';
import CollapsePanel from '../../../components/CollapsePanel';
// import ProductAccordion from './ProductAccordion';
import ProductSummaryTable from './ProductSummaryTable';
import ProductFeatures from './ProductFeatures';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import checkSvg from '../../../assets/images/misc/check.svg';
import {
  // faCheckCircle,
  faTimesCircle,
} from '@fortawesome/free-solid-svg-icons';

const OPTIONS = Object.freeze([
  {
    title: 'Additional Features',
    highlights: [
      {
        included: true,
        description:
          'Manage coverage for all crypto and exchanges in one policy',
      },
      {
        included: true,
        description: 'Flexible deductible option',
      },
      {
        included: true,
        description: 'Transparent claims process',
      },
    ],
  },
  {
    title: `What's Not Covered?`,
    highlights: [
      {
        included: false,
        description: 'Third-party hardware and software wallets',
      },
      {
        included: false,
        description: 'Loss due to user errors',
      },
      {
        included: false,
        description: 'Asset depreciation due to market volatility',
      },
    ],
  },
]);

const ProductSummary = props => {
  const { detailOnly } = props;
  const [visibleAccordion, setVisibleAccordion] = useState(false);

  if (detailOnly) {
    return (
      <div id="product-summary" className="product-summary">
        <div className="row p-md-5 pr-md-5">
          <div className="col-lg-12">
            <div className="product-table-holder  table-responsive ">
              <ProductSummaryTable />
            </div>
          </div>
        </div>
        <div className="pl-md-5 pr-md-5">
          <ProductFeatures options={OPTIONS} />
        </div>
      </div>
    );
  }

  const addFeatures = OPTIONS[0];
  const notCovered = OPTIONS[1];

  return (
    <div id="product-summary" className="product-summary">
      <div className="container">
        <ViewFadeGroup
          dir="up"
          visible={visibleAccordion}
          onEnterViewport={() => setVisibleAccordion(true)}
          onLeaveViewport={() => setVisibleAccordion(true)}
        >
          <div className="d-md-flex mt-5">
            <div className="product-table-holder  table-responsive shadow gr-2">
              <ProductSummaryTable />
            </div>
            <div className="mt-3 mt-md-0 ml-md-3 gr-4 rounded-xl bg-primary text-white p-3 p-lg-4">
              <h5>Additional Features</h5>
              <hr />
              {addFeatures.highlights.map(item => (
                <div
                  className="d-flex align-items-center"
                  key={item.description}
                >
                  {/* <FontAwesomeIcon
                    icon={faCheckCircle}
                    style={{ fontSize: 24 }}
                  /> */}
                  <img src={checkSvg} width={30} alt={'Check'} />
                  <div className="ml-1 ml-md-3 mb-3 mt-2">
                    {item.description}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </ViewFadeGroup>

        <ViewFadeGroup dir="up">
          <CollapsePanel title="What's not Covered?" initOpen>
            <div>
              {notCovered.highlights.map(item => (
                <div
                  className="d-flex align-items-center"
                  key={item.description + 'cover'}
                >
                  <span className="text-danger">
                    <FontAwesomeIcon
                      icon={faTimesCircle}
                      style={{ fontSize: 24 }}
                    />
                  </span>
                  <div className="ml-1 ml-md-3 mb-3 mt-2">
                    {item.description}
                  </div>
                </div>
              ))}
            </div>
          </CollapsePanel>
        </ViewFadeGroup>
      </div>
    </div>
  );
};

ProductSummary.propTypes = {
  detailOnly: PropTypes.bool,
};

ProductSummary.defaultProps = {
  detailOnly: false,
};

export default ProductSummary;
