import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faDatabase,
  faFile,
  faInfinity,
  faShoppingBag,
  faShoppingCart,
} from '@fortawesome/free-solid-svg-icons';

import General from './general';
import Policy from './policy';
import Exchanges from './exchanges';
import Claims from './claims';
import Payment from './payment';
import Home from './pages/home';
import Services from './pages/services';
import Individuals from './pages/individuals';
import Institutions from './pages/institutions';
import Smbs from './pages/smbs';
import AboutUs from './pages/aboutus';

const TABS = [
  {
    value: 'general',
    label: 'General FAQs',
    icon: (
      <FontAwesomeIcon className="text-primary" icon={faInfinity} size={'3x'} />
    ),
    body: General,
  },
  {
    value: 'policy',
    label: 'Policy FAQs',
    icon: (
      <FontAwesomeIcon className="text-primary" icon={faFile} size={'3x'} />
    ),
    body: Policy,
  },
  {
    value: 'exchange',
    label: 'Exchanges & Coins',
    icon: (
      <FontAwesomeIcon className="text-primary" icon={faDatabase} size={'3x'} />
    ),
    body: Exchanges,
  },
  {
    value: 'payment',
    label: 'Payment FAQs',
    icon: (
      <FontAwesomeIcon
        className="text-primary"
        icon={faShoppingCart}
        size={'3x'}
      />
    ),
    body: Payment,
  },
  {
    value: 'claims',
    label: 'Claims FAQs',
    icon: (
      <FontAwesomeIcon
        className="text-primary"
        icon={faShoppingBag}
        size={'3x'}
      />
    ),
    body: Claims,
  },
];

const TileNavs = props => {
  const { value, onChange } = props;
  const baseNavClass = `tile-nav shadow text-center m-1 mouse-pointer`;
  return (
    <div className="tile-navs d-flex flex-wrap justify-content-center align-items-center">
      {TABS.map(t => (
        <div
          key={t.value}
          onClick={() => onChange(t.value)}
          data-action-id={`faq-nav-${t.value}`}
          className={`${baseNavClass} ${
            t.value === value ? 'border-primary' : ''
          }`}
        >
          <div style={{ opacity: t.value === value ? 1 : 0.2 }}>{t.icon}</div>
          {t.label}
        </div>
      ))}
    </div>
  );
};

const FaqBody = ({ value }) => {
  const targetVal = value || TABS[0].value;
  return (
    <div className="container">
      {TABS.map(t => (
        <div
          key={t.value}
          className={`${t.value !== targetVal ? 'd-none' : ''}`}
        >
          <t.body />
        </div>
      ))}
    </div>
  );
};

export const FaqSection = () => {
  return (
    <div>
      <div className="container sub-section">
        <h2 className=" font-weight-bold text-center">
          <span className="underlined-curve pr-4">&nbsp;&nbsp;FAQ</span>
        </h2>
      </div>
      <FaqBody />
    </div>
  );
};

export const FaqByPage = ({ page }) => {
  let Component;

  switch (page) {
    case 'home':
      Component = Home;
      break;
    case 'services':
      Component = Services;
      break;
    case 'individuals':
      Component = Individuals;
      break;
    case 'institutions':
      Component = Institutions;
      break;
    case 'smbs':
      Component = Smbs;
      break;
    case 'aboutus':
      Component = AboutUs;
      break;
    default:
      Component = Home;
  }

  return (
    <div>
      <div className="container sub-section mb-0">
        <h2 className=" font-weight-bold text-center">
          <span className="underlined-curve pr-4">&nbsp;&nbsp;FAQ</span>
        </h2>
        <div className="f6 text-center font-weight-semi-bold mt-4 opacity-50 ">
          <small>Have questions? We're here to help</small>
        </div>
      </div>
      <div className="container">
        <Component />
      </div>
    </div>
  );
};

const Faq = () => {
  const [tab, setTab] = useState(TABS[0].value);
  return (
    <div
      id="faq"
      className="p-2 ml-auto mr-auto"
      style={{ minHeight: '80vh', marginTop: 100 }}
    >
      <Helmet>
        <title>
          Breach Insurance | Frequently asked questions about crypto insurance
        </title>
        <meta
          name="description"
          content="Learn more about answers to questions like: Can you insure cryptocurrency? Is Bitcoin insured? Do you insure crypto on Coinbase and Gemini? And more."
        />
      </Helmet>
      <TileNavs value={tab} onChange={setTab} />
      <FaqBody value={tab} />
    </div>
  );
};

export default Faq;
