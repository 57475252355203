import React from 'react';

import checkCircle from '../../../assets/images/misc/check.svg';

const ProductSummaryTable = () => (
  <table className="product-summary-table">
    <thead>
      <tr>
        <th>
          <h4 className="font-weight-bold">Coverage Details</h4>
        </th>
        <th>
          <h5 className="product text-center">
            Crypto
            <br /> Shield
          </h5>
        </th>
        {/* <th>
          <h5 className="product text-center">
            Crypto
            <br />
            <span className="text-truncate">
              Shield
              <span className="text-info">
                <sup>Pro</sup>
              </span>
            </span>
          </h5>
        </th> */}
      </tr>
    </thead>
    <tbody>
      <tr>
        <td>Hacks of exchange wallets</td>
        <td>
          <img src={checkCircle} width={30} alt="Hack" />
        </td>
      </tr>
      <tr>
        <td>
          Social engineering events <br /> caused by hacks to an exchange
        </td>
        <td className="text-center">
          <img src={checkCircle} width={30} alt="social" />
        </td>
      </tr>
      <tr>
        <td>Up to $1M in crypto coverage</td>
        <td>
          <img src={checkCircle} width={30} alt="Coverage" />
        </td>
      </tr>
    </tbody>
  </table>
);

export default ProductSummaryTable;
