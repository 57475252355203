import React, { useEffect } from 'react';

import { Link, useHistory } from 'react-router-dom';
import breachCircle from '../assets/images/misc/circles-bg.png';
import breachCircleGrad from '../assets/images/misc/circle-gradient.png';

import heroVideo from '../assets/videos/Landing/Hero1.webm';

import heroVideoMov from '../assets/videos/Landing/Hero1.mov';

import { registerBeaconByPage } from '../marketingEvents';

const LandingCover = ({ beaconKeys }) => {
  const history = useHistory();
  useEffect(() => {
    if (beaconKeys) {
      registerBeaconByPage('services')(beaconKeys);
    }
  }, [beaconKeys]);
  return (
    <div id="home" className="hero-section">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-12 mb-5 mb-md-0">
            <img className="bg-circle" src={breachCircle} alt="circle" />
            <img className="bg-grad" src={breachCircleGrad} alt="gradient" />
            <video autoPlay={true} className="hero-video" loop={true}>
              <source src={heroVideoMov} type="video/mp4" />
              <source src={heroVideo} type="video/webm" />
            </video>
            <a
              className="landing-announcement"
              // onClick={() => history.push('#contact-us')}
              href="#contact-us"
            >
              <h5 className="f6">
                <span className="text-secondary font-weight-bold mr-2">
                  New
                </span>
                <span className="text-white font-weight-light">
                  Receive $1,000 by referring a client to Breach
                </span>
              </h5>
            </a>
            <h3
              className="mt-1 mb-3 text-white font-weight-bold position-relative"
              style={{ zIndex: 20 }}
            >
              <span>
                <span>Insurance For </span>
                <br />
                The&nbsp;
                <span className="text-info">Digital Era</span>
              </span>{' '}
              {/* stored in
                  supported <span className="font-weight-bold">exchanges</span>
                  */}
            </h3>

            <div className="mb-3 position-relative">
              <span className="f5 text-white ">
                Underwriters that understand
              </span>
              &nbsp;{' '}
              <span className="f5  text-white underlined-curve  font-weight-semi-bold info">
                emerging risks
              </span>
            </div>
            <br />
            <div className="position-relative d-flex flex-wrap mb-4">
              <div className="d-flex flex-column flex-md-row">
                <Link
                  className="btn btn-info pl-5 pr-5 pt-2 pb-2 mr-3 mt-2"
                  // onClick={this.handleGetAQuote}
                  to="/cryptoshieldpro"
                >
                  About Crypto Shield<sup>Pro</sup>&nbsp;
                </Link>
                <Link
                  className="btn btn-info reverse pl-5 pr-5 pt-2 pb-2 mr-3 mt-2"
                  // onClick={this.handleGetAQuote}
                  to="/cryptoshieldproplatforms"
                >
                  Crypto Shield<sup>Pro</sup> for Platforms
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LandingCover;
