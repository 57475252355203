import React from 'react';
import CollapseSection from '../partialComponents/CollapseSection';

const Claims = () => {
  return (
    <div>
      <CollapseSection title="How do I submit a claim? ">
        You can submit a claim directly through your customer dashboard. When
        submitting a claim, you will be prompted to provide details of the theft
        event, which will help us process your claim quickly.
      </CollapseSection>

      <CollapseSection title="How will claims work?">
        Breach works with you and our exchange partners to get to the bottom of
        the issue. After the investigation, we aim to process your claim as
        efficiently and as timely as possible.
      </CollapseSection>
      <CollapseSection title="How fast will my claim be processed? ">
        Our goal is to make you whole as quickly as possible. We will work as
        quickly to review and pay your claim, so that you can get back to having
        access to your crypto assets.
      </CollapseSection>
      <CollapseSection title="What happens if I get reimbursed by the exchange?">
        We will always look to reimburse you as quickly as we can for approved
        claims. In some scenarios, your exchange may decide at a later date to
        reimburse you for the same event. If your exchange decides to reimburse
        you for a hack event, we will ask you to refund the initial claims
        payment as our product is built to help make you whole.
      </CollapseSection>
    </div>
  );
};

export default Claims;
