import React from 'react';
import CollapseSection from '../partialComponents/CollapseSection';

const Payment = () => {
  return (
    <div>
      <CollapseSection title="What payment methods do you accept?">
        We accept Visa and Mastercard credit and debit cards.
      </CollapseSection>

      <CollapseSection title="How will I be billed for my premium?">
        For annual payment plans, we bill annually at the time of purchase and
        renewal. For monthly payment plans, we bill each month on the day of the
        original purchase.
      </CollapseSection>
    </div>
  );
};

export default Payment;
