import gql from 'graphql-tag';

export const SUBSCRIBE = gql`
  mutation registerWaitlist($form: FormDetails!) {
    registerWaitlist(form: $form) {
      success
      errMsg
      fields {
        email
      }
    }
  }
`;

export const SEND_MESSAGE = gql`
  mutation sendMessage($message: MessageDetails!) {
    sendMessage(message: $message) {
      success
      errMsg
      fields {
        email
      }
    }
  }
`;
