import { toast } from 'react-toastify';

export default {
  success(msg, options = {}) {
    return toast.success(msg, {
      ...options,
      className: 'toast-success',
    });
  },
  error(msg, options = {}) {
    return toast.error(msg, {
      ...options,
      className: 'toast-error',
    });
  },
  warn(msg, options = {}) {
    return toast.warn(msg, {
      ...options,
      className: 'label-warning',
    });
  },
  info(msg, options = {}) {
    return toast.info(msg, {
      ...options,
      className: 'label-info',
    });
  },
  blank: toast,
};
