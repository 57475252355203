import React, { useState } from 'react';
import { NotFound, filterItems } from './questions';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';

const FaqBody = ({ sections }) => {
  const [searchText, setSearchText] = useState();

  return (
    <div>
      <div className="input-with-icon">
        <input
          type="text"
          className="form-control mt-2"
          placeholder="Search Here..."
          onChange={e => setSearchText(e.target.value)}
        />
        <div>
          <FontAwesomeIcon icon={faSearch} className="opacity-50" />
        </div>
      </div>
      {sections.filter(filterItems(searchText)).map((cmp, key) => (
        <cmp.component key={key} />
      ))}
      <NotFound />
    </div>
  );
};

export default FaqBody;
