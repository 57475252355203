import React from 'react';
import PropTypes from 'prop-types';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

import checkCircle from '../../../assets/images/misc/check.svg';

const ProductFeatures = props => {
  const { options } = props;

  return (
    <div className="row">
      {options.map((item, idx) => (
        <div className="col-md-6" key={`${item.title}`}>
          <div
            className={`product-accordion-item w-100 gr-2 text-white `}
            style={{ borderRadius: 10 }}
          >
            <h4 className="font-weight-bold">{item.title}</h4>

            <div>
              <hr />
              {item.highlights.map(highlight => (
                <div
                  key={`${item.title}${highlight.description}`}
                  className="d-flex mb-3"
                >
                  <div
                    className={`mr-3 text-${
                      highlight.included ? 'white' : 'danger'
                    }`}
                  >
                    {highlight.included ? (
                      <img src={checkCircle} alt="check" width="30" />
                    ) : (
                      <FontAwesomeIcon icon={faTimes} />
                    )}
                    {/* <FontAwesomeIcon
                      icon={highlight.included ? faCheck : faTimes}
                    /> */}
                  </div>
                  <div className="text-white">{highlight.description}</div>
                </div>
              ))}
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

ProductFeatures.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      highlights: PropTypes.arrayOf(
        PropTypes.shape({
          included: PropTypes.bool,
          description: PropTypes.string,
        }),
      ),
    }),
  ),
};

export default ProductFeatures;
