import React from 'react';
import Slider from '../../components/Slider';
import ViewFadeGroup from '../../components/Fade/FadeGroup';
import person1Img from '../../assets/images/team/jpg/TJ.jpg';
import nayms from '../../assets/images/testimonials/nayms.jpg';
import credora from '../../assets/images/testimonials/credora.jpg';
import salter from '../../assets/images/testimonials/salter.png';
import rogers from '../../assets/images/testimonials/rogers2.jpg';
import rw3 from '../../assets/images/testimonials/rw3.jpeg';
import lightshed from '../../assets/images/testimonials/lightshed.jpeg';
import kresus from '../../assets/images/testimonials/kresus.jpeg';

const TestimonialCard = ({
  profilePic = person1Img,
  title = 'Happy Client',
  fullName = 'TJ Olonilua',
  description = 'Sed ut perspiciatis unde omnis iste natus error sit volu accusantium doloremque laudantium sed.',
}) => (
  <div className="testimonial-card">
    <div className="d-flex align-items-center">
      <div>
        <img
          src={profilePic}
          alt={title}
          className="rounded-circle img-fluid"
          style={{ width: 50 }}
        />
      </div>
      <div className="ml-3">
        <span className="text-bold">{fullName}</span>
        <div>
          <small>{title}</small>
        </div>
      </div>
    </div>
    <br />
    <p>{description}</p>
  </div>
);

function Testimonials() {
  return (
    <div id="testimonials" className="testimonials bg-primary">
      <ViewFadeGroup dir="up">
        <div className="container">
          <div className="row justify-content-center">
            <h3 className="text-white font-weight-bold">
              Words From Our Partners
            </h3>
          </div>

          {/* <p className="text-white text-center mt-1">Lorem Ipsum Dolor...</p> */}

          <Slider>
            <TestimonialCard
              fullName="Dan Roberts"
              title="Co-Founder and CEO, Nayms"
              profilePic={nayms}
              description={
                <span>
                  Very few companies in crypto insurance have gone through the
                  necessary learnings and iterations than Breach, which has
                  resulted in a project that is set to become a major player in
                  both the digital asset space and the Insurtech industry. We
                  are excited to partner with Breach on their Crypto Shield
                  <sup>Pro</sup>&nbsp; offering to accelerate necessary
                  protection for a growing base of cryptocurrency users. The
                  space needs Breach. Period.
                </span>
              }
            />
            <TestimonialCard
              fullName="Darshan Vaidya"
              title="Founder and CEO, Credora"
              profilePic={credora}
              description="Breach is building transparent and data-driven infrastructure that finally gives our institutional clients the protection they need when interacting with crypto. 
              Institutions and individuals already see the value of crypto. What they need help with
               is untangling the risks - Breach is solving that."
            />

            <TestimonialCard
              fullName="Don Stalter"
              title="GFC partner and North America Lead"
              profilePic={salter}
              description="After a thorough industry deep dive, we concluded Breach’s innovative approach to crypto insurance is second
              to none. Given our experience partnering with category creators and global distributors, I am particularly excited to support
              Breach in its aggressive growth goals."
            />

            <TestimonialCard
              fullName="Ted Rogers"
              title="Investor"
              profilePic={rogers}
              description="Existing insurance policies often neglect to cover areas of real risk and serve mainly as marketing tools for
              crypto companies. Eyhab and the Breach team have the insurance background and technical expertise to finally bring effective,
              scalable coverage to individuals and businesses in our industry."
            />
            <TestimonialCard
              fullName="Joseph Bruzessi"
              title="RW3 Ventures General Partner"
              profilePic={rw3}
              description="As an investor and entrepreneur with more than six years of experience in the crypto industry, 
              I have observed a growing demand for insurance. In my past role, where I helped develop one of the leading crypto custodians, 
              I saw firsthand the imbalance between the supply and demand for insurance. 
              I am confident that the Breach team has the necessary combination of experience and innovation to 
              address one of the industry's most significant challenges, and I am thrilled to support their mission."
            />
            <TestimonialCard
              fullName="Jamie Seltzer"
              title="LightShed Ventures General Partner, Co-Founder"
              profilePic={lightshed}
              description="The crypto industry has been grappling with rising regulatory scrutiny while looking for ways to gain credibility 
              with both investors and the public. We view insurance as one of the first steps in professionalizing and safeguarding the industry, 
              and we believe the Breach team has the experience, approach, and timing to the market to bring effective coverage to both individuals 
              and businesses."
            />
            <TestimonialCard
              fullName="Lucas Harris"
              title="Kresus, Head of Operations"
              profilePic={kresus}
              description="Working with Breach has been incredibly smooth and efficient, allowing Kresus to offer the first self-custody crypto and 
              NFT wallet with built in regulated insurance. While setting up insurance is often expected to be an onerous and tedious process; the 
              Breach team has made it painless and affordable."
            />
          </Slider>
        </div>
      </ViewFadeGroup>
    </div>
  );
}

export default Testimonials;
