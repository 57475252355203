import React, { useEffect, useRef } from 'react';
import SlickSlider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const defaultSettings = {
  dots: true,
  infinite: true,
  slidesToShow: 3,
  slidesToScroll: 1,
  autoplay: true,
  speed: 2000,
  autoplaySpeed: 2000,
  cssEase: 'linear',
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        dots: true,
      },
    },
  ],
};

const Slider = ({ settings, children }) => {
  const sliderApi = useRef({
    slickPause: () => false,
    slickPlay: () => false,
  });

  useEffect(() => {
    let pause = false;
    const token = setInterval(() => {
      if (!pause) {
        sliderApi.current.slickPause();
        pause = true;
      } else {
        sliderApi.current.slickPlay();
        pause = false;
      }
    }, 3000);
    return () => clearInterval(token);
  }, []);
  const targetSetttings = settings || defaultSettings;
  return (
    <SlickSlider {...targetSetttings} ref={api => (sliderApi.current = api)}>
      {children}
    </SlickSlider>
  );
};

export default Slider;
