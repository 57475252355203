import React from 'react';
import { Link } from 'react-router-dom';
import _ from 'lodash';
import { useQuery } from '@apollo/react-hooks';
import { GET_PROGRAMS } from '../../../graphql/Queries';
import CollapseSection from '../../partialComponents/CollapseSection';
import Table from '../../partialComponents/Table';
import breachMap from '../../../assets/images/misc/map.png';
import {
  ABOUT_US,
  CONFIDENTLY_HOW,
  CONFIDENTLY_HOW_TITLE,
  DIFFERENT,
  DIFFERENT_INDIVIDUALS,
  DIFFERENT_INDIVIDUALS_TITLE,
  DIFFERENT_INSTITUTIONS,
  DIFFERENT_INSTITUTIONS_TITLE,
  DIFFERENT_SMB,
  DIFFERENT_SMB_TITLE,
  FREQ_HACK,
  FREQ_HACK_TITLE,
  OTHER,
  OTHER_TITLE,
  WHERE_ABOUT_US,
  WHERE_ABOUT_US_TITLE,
  WHY_CRYPTO_SHIELD_PRO,
} from './constants';

const HACK_COLUMNS = [
  {
    name: 'year',
    displayName: 'Year',
  },
  {
    name: 'total',
    displayName: (
      <div>
        Crypto Value($USD) <br />
        <small>(valuation as of 12/31/21)</small>
      </div>
    ),
  },
];

const HACK_DATA = [
  { year: 2011, total: `$3.2B`, numHacks: 4 },
  { year: 2012, total: `$6.0B`, numHacks: 4 },
  { year: 2013, total: `$282.5M`, numHacks: 3 },
  { year: 2014, total: `$39.9B`, numHacks: 6 },
  { year: 2015, total: `$1.4B`, numHacks: 5 },
  { year: 2016, total: `$6.3B`, numHacks: 4 },
  { year: 2017, total: `$426M`, numHacks: 4 },
  { year: 2018, total: `$633M`, numHacks: 8 },
  { year: 2019, total: `$696M`, numHacks: 10 },
  { year: 2020, total: `$1.4B`, numHacks: 8 },
  { year: 2021, total: `$455M`, numHacks: 6 },
  {
    year: <span className="text-bold text-primary">Total</span>,
    numHacks: <span className="text-bold  text-primary">62</span>,
    total: <span className="text-bold text-primary">$60.9B</span>,
  },
];

export const FrequencyOfHacks = () => {
  return (
    <CollapseSection title="How frequent are exchange hacks?">
      While exchanges continue to strengthen their cybersecurity to combat
      hackers, the unfortunate reality is that these events do happen. We’ve
      compiled that data in the table below.
      <br />
      <br />
      <div className="ml-sm-5 mr-sm-5">
        <Table
          className="text-center"
          columns={HACK_COLUMNS}
          rows={HACK_DATA}
        />
      </div>
    </CollapseSection>
  );
};
export const FrequencyOfHacksEntry = {
  component: FrequencyOfHacks,
  tags: [FREQ_HACK_TITLE, FREQ_HACK],
};

export const Where = () => {
  const { data } = useQuery(GET_PROGRAMS, {
    variables: { programId: 'crypto-exchange-cover' },
  });
  const states = _.get(data, 'programs[0].regions[0].states', []);

  return (
    <CollapseSection title="Where is Breach insurance available?">
      Breach CryptoShield insurance is currently available in{' '}
      {states.join(', ')} and we’ll quickly expand countrywide.
      <img
        src={breachMap}
        alt="Crypto insurance Breach map"
        className="img-fluid mx-auto d-block"
        style={{ maxHeight: 800 }}
      />
    </CollapseSection>
  );
};

export const WhereEntry = {
  component: Where,
  tags: [`Where is Breach insurance available`, `expand countrywide`],
};

export const WhereAboutUs = () => {
  return (
    <CollapseSection title={WHERE_ABOUT_US_TITLE}>
      {WHERE_ABOUT_US}
    </CollapseSection>
  );
};

export const WhereAboutUsEntry = {
  component: WhereAboutUs,
  tags: [WHERE_ABOUT_US_TITLE, WHERE_ABOUT_US],
};

export const About = () => {
  return <CollapseSection title="About Breach">{ABOUT_US}</CollapseSection>;
};

export const AboutEntry = {
  component: About,
  tags: [ABOUT_US, 'About Breach'],
};

export const Different = () => {
  return (
    <CollapseSection title="What makes Breach different from alternative insurance solutions?">
      {DIFFERENT}
    </CollapseSection>
  );
};

export const DifferentEntry = {
  component: Different,
  tags: [
    DIFFERENT,
    'What makes Breach different from alternative insurance solutions',
  ],
};

export const DifferentInstitutions = () => {
  return (
    <CollapseSection title={DIFFERENT_INSTITUTIONS_TITLE}>
      {DIFFERENT_INSTITUTIONS}
    </CollapseSection>
  );
};
export const DifferentInstitutionsEntry = {
  component: DifferentInstitutions,
  tags: [DIFFERENT_INSTITUTIONS, DIFFERENT_INSTITUTIONS_TITLE],
};

export const DifferentIndividuals = () => {
  return (
    <CollapseSection title={DIFFERENT_INDIVIDUALS_TITLE}>
      {DIFFERENT_INDIVIDUALS}
    </CollapseSection>
  );
};
export const DifferentIndividualsEntry = {
  component: DifferentInstitutions,
  tags: [DIFFERENT_INDIVIDUALS_TITLE, DIFFERENT_INDIVIDUALS],
};

export const DifferentSMB = () => {
  return (
    <CollapseSection title={DIFFERENT_SMB_TITLE}>
      {DIFFERENT_SMB}
    </CollapseSection>
  );
};
export const DifferentSMBEntry = {
  component: DifferentInstitutions,
  tags: [DIFFERENT_SMB_TITLE, DIFFERENT_SMB],
};

export const ProtectedRisks = () => {
  return (
    <CollapseSection title="What are some risks protected by Breach’s proprietary products?">
      Breach has created a product for retail investors to protect against theft
      called Crypto Shield, you can find it on our{' '}
      <Link to="/cryptoshield" className="text-info">
        Individuals page&nbsp;
      </Link>
      . More recently, Breach launched a product for Institutions to protect
      themselves from theft at their qualified custodian, that product is called
      Crypto Shield Pro, you can find this on our{' '}
      <Link to="/cryptoshieldpro" className="text-info">
        Institutions page&nbsp;
      </Link>
      .
    </CollapseSection>
  );
};
export const ProtectedRisksEntry = {
  component: ProtectedRisks,
  tags: [
    `Breach launched a product for Institutions to protect themselves from
    theft at their qualified custodian, that product is called Crypto Shield
    Pro, you can find this on our Insitution Page`,
    'What are some risks protected by Breach’s proprietary products',
    `More recently, Breach launched a product for Institutions to protect
    themselves from theft at their qualified custodian, that product is called
    Crypto Shield Pro, you can find this on our`,
    'Individuals Page',
    'Institutions Page',
  ],
};

export const OtherHelp = () => {
  return <CollapseSection title={OTHER_TITLE}>{OTHER}</CollapseSection>;
};

export const OtherHelpEntry = {
  component: OtherHelp,
  tags: [OTHER_TITLE, OTHER],
};

export const NotFound = () => {
  return (
    <CollapseSection title="I have a question that isn’t on this FAQ, where should I go?">
      If you have any questions that do not exist in this FAQ, please email us
      at &nbsp;
      <a
        className="text-primary"
        href="mailto:care@breachinsured.com"
        rel="noopener noreferrer"
        target="_blank"
      >
        care@breachinsured.com
      </a>{' '}
      or start a chat to speak directly with one of our team members.
    </CollapseSection>
  );
};

export const WhyCryptoShieldPro = () => {
  return (
    <CollapseSection
      title={
        <span>
          Why do I need Crypto Shield<sup className="text-info">Pro</sup>?
        </span>
      }
      titleId="WhatCryptoShieldPro"
    >
      Institutional crypto investors require adequate risk management and risk
      transfer options, which we are now able to offer to a variety of
      institutions operating in this space. Crypto Shield<sup>Pro</sup> allows
      our insureds to take control of their risk management as the named insured
      on their own crypto insurance policy to fulfill obligations to key
      stakeholders (e.g. board members, limited partners, investors, clients,
      users, etc.).
    </CollapseSection>
  );
};

export const WhyCryptoShieldProEntry = {
  component: WhyCryptoShieldPro,
  tags: [`Why do I need Crypto Shield Pro`, WHY_CRYPTO_SHIELD_PRO],
};

export const ConfidentlyHow = () => {
  return (
    <CollapseSection title={CONFIDENTLY_HOW_TITLE}>
      {CONFIDENTLY_HOW}
    </CollapseSection>
  );
};

export const filterItems = searchText => item => {
  if (!searchText) {
    return true;
  }
  const searchTokens = searchText
    .split(' ')
    .filter(Boolean)
    .map(item => item.toLowerCase());

  const lcTags = item.tags.join(' ').toLowerCase();
  return searchTokens.every(tok => lcTags.includes(tok));
};
