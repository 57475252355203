const makeUri = (url, suffix = '') => {
  if (url.indexOf('localhost') !== -1) {
    return `http://localhost:3000/${suffix}`;
  }

  let uri = url.replace(/www./, '');
  if (uri.substr(uri.length - 1) === '/') {
    uri += suffix;
  } else {
    uri += `/${suffix}`;
  }
  return uri;
};

/**
 * Creates the url for tally forms. localhost based url's
 * won't work for tally, but not a concern.
 *
 * @param {*} url
 * @param {*} suffix
 * @returns
 */
const makeFormsUri = (url, suffix = '') => {
  let uri = url.replace(/www./, '');
  uri = uri.replace(/^http:\/\//, 'http://forms.');
  uri = uri.replace(/^https:\/\//, 'https://forms.');
  if (uri.substr(uri.length - 1) === '/') {
    uri += suffix;
  } else {
    uri += `/${suffix}`;
  }
  return uri;
};

const makeDashboardUri = (url, suffix = '') => {
  if (url.indexOf('localhost') !== -1) {
    return `http://localhost:3000/${suffix}`;
  }
  let uri = url.replace(/www./, '');
  uri = uri.replace(/^http:\/\//, 'http://www.dashboard.');
  uri = uri.replace(/^https:\/\//, 'https://www.dashboard.');
  if (uri.substr(uri.length - 1) === '/') {
    uri += suffix;
  } else {
    uri += `/${suffix}`;
  }
  return uri;
};

module.exports = {
  makeUri,
  makeFormsUri,
  makeDashboardUri,
};
