import React, { useState, useRef, useEffect } from 'react';
import { useMutation } from '@apollo/react-hooks';
import toast from '../../../toast';
import ActionButton, { STATE } from '../../../components/ActionButton.js';
import { REGISTER_BROKER } from './graphql';

const RegisterBroker = () => {
  const inputRef = useRef(null);

  const [registerBroker] = useMutation(REGISTER_BROKER, {
    variables: { email: '' },
    update(cache, { data: { registerBroker: response } }) {
      if (!response.success) {
        toast.error(response.errMsg);
        setButtonState(STATE.ERROR);
        errorTimeout.current = setTimeout(() => {
          setButtonState(STATE.NOTHING);
        }, 2500);
        return null;
      }
      successTimeout.current = setTimeout(() => {
        setButtonState(STATE.SUCCESS);
        toast.success('You have been registered');
      }, 300);
      resetTimeout.current = setTimeout(() => {
        setButtonState(STATE.NOTHING);
      }, 1000);
      return response;
    },
  });

  const handleSubmit = async event => {
    event.preventDefault();
    if (!inputRef.current.value) return null;
    setButtonState(STATE.LOADING);

    try {
      await registerBroker({
        variables: { email: inputRef.current.value },
      });
    } catch (err) {
      toast.error(`Error registering your email. Please try again`);
      setButtonState(STATE.ERROR);
      errorTimeout.current = setTimeout(() => {
        setButtonState(STATE.NOTHING);
      }, 2000);
    }
  };

  const successTimeout = useRef();
  const resetTimeout = useRef();
  const errorTimeout = useRef();
  // eslint-disable-next-line arrow-body-style
  useEffect(() => {
    return () => {
      clearTimeout(successTimeout.current);
      clearTimeout(resetTimeout.current);
      clearTimeout(errorTimeout.current);
    };
  }, []);

  const [buttonState, setButtonState] = useState();

  return (
    <div id="register-broker" className="container">
      <div className="main-section text-white d-md-flex align-items-center">
        <div>
          <h2 className="font-weight-bold">
            Are you <span className="text-info">a broker?</span>
          </h2>
          <p className="f5">Register Now!</p>
        </div>

        <div className="flex-fill ml-md-4 mr-md-4">
          <input
            style={{ height: 50 }}
            ref={inputRef}
            type="email"
            className="form-control "
            placeholder="Your Email Address"
          />
        </div>
        <ActionButton
          className="end"
          buttonClassName="font-weight-bold btn btn-primary mt-4 mt-md-0"
          type="submit"
          controlled
          state={buttonState}
          onClick={e => handleSubmit(e)}
          durationSuccess={1500}
        >
          <nobr>Sign Up</nobr>
        </ActionButton>
      </div>
    </div>
  );
};

export default RegisterBroker;
