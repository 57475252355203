import React from 'react';
import {
  AboutEntry,
  DifferentEntry,
  OtherHelpEntry,
  WhereAboutUsEntry,
} from './questions';
import FaqBody from './faqBody';

const Sections = [
  AboutEntry,
  DifferentEntry,
  OtherHelpEntry,
  WhereAboutUsEntry,
];

const AboutUs = () => {
  return <FaqBody sections={Sections} />;
};

export default AboutUs;
