/* global XMLHttpRequest */
import get from 'lodash/get';

// the module state properties
const state = {
  // constant settings
  method: 'GET',
  headers: [
    {
      name: 'Content-Type',
      value: 'application/json;charset=UTF-8',
    },
  ],
  relativeUrl: 'api/runtimeConfig',
  // variables
  config: {},
  loaded: false,
  loadedListeners: [],
};

// this just issues a standard XMLHttpRequest and returns a promise which
// resolves or reject based on request result
const getRequest = uri =>
  new Promise((resolve, reject) => {
    const xmlhttp = new XMLHttpRequest();
    xmlhttp.onreadystatechange = () => {
      if (xmlhttp.readyState === 4 && xmlhttp.status === 200) {
        resolve(xmlhttp.responseText);
        return;
      }
      if (xmlhttp.status === 500) {
        reject(xmlhttp.responseText);
        return;
      }
      if (xmlhttp.status === 400) {
        reject(xmlhttp.responseText);
      }
    };
    xmlhttp.onerror = error => {
      // eslint-disable-next-line prefer-promise-reject-errors
      reject({ status: xmlhttp.statusText, error });
    };
    xmlhttp.open(state.method, uri + state.relativeUrl, true);
    for (let i = 0; i < state.headers.length; i += 1) {
      const header = state.headers[i];
      const { name, value } = header;
      xmlhttp.setRequestHeader(name, value);
    }
    xmlhttp.send();
  });

// starts loading and returns a promise which resolves when the load is finished
// you may or may not wait until it resolves, because the `read` function below
// waits for loading anyways
export const load = async uri => {
  const result = await getRequest(uri);
  try {
    state.config = JSON.parse(result);
    state.loaded = true;
  } catch (err) {
    console.error(`Error parsing config response: ${err.message}`);
  }
  for (let i = 0; i < state.loadedListeners.length; i += 1) {
    const listener = state.loadedListeners[i];
    listener();
  }
};

// this creates a new promise which is resolved when configuration loads
const blockUntilLoaded = () =>
  new Promise(resolve => {
    if (state.loaded) {
      resolve();
    } else {
      state.loadedListeners.push(resolve);
    }
  });

// read a configuration
// we need to wait until it is loaded
export const read = async name => {
  await blockUntilLoaded();
  return get(state.config, name);
};
