export const register = keys => {
  if (!keys) {
    return;
  }
  const { fb, linkedIn, reddit } = keys;
  const { fbq, rdt } = window;

  fbq('init', fb);
  fbq('track', 'PageView');

  rdt('init', reddit);
  rdt('track', 'PageVisit');

  // eslint-disable-next-line no-underscore-dangle
  window._linkedin_data_partner_ids = window._linkedin_data_partner_ids || [];
  // eslint-disable-next-line no-underscore-dangle
  window._linkedin_data_partner_ids.push(linkedIn);
};

export const registerBeaconByPage = pageId => keys => {
  const { Beacon } = window;
  if (Beacon) {
    Beacon('destroy');
  }
  Beacon('init', keys?.[pageId]);
};

export const viewContent = () => {
  window.fbq('track', 'ViewContent');
  window.rdt('track', 'ViewContent');
};

export const contactUs = () => {
  window.fbq('track', 'Lead');
  window.rdt('track', 'Lead');
};

export const waitList = () => {
  window.fbq('track', 'WaitList');
  window.rdt('track', 'Lead');
};
