import React from 'react';
import FaqBody from './faqBody';
import {
  AboutEntry,
  DifferentIndividualsEntry,
  FrequencyOfHacksEntry,
  WhereEntry,
} from './questions';

const Sections = [
  AboutEntry,
  DifferentIndividualsEntry,
  FrequencyOfHacksEntry,
  WhereEntry,
];

const Individuals = () => {
  return <FaqBody sections={Sections} />;
};

export default Individuals;
