import React, { useState } from 'react';
import ViewFadeImage from './Fade/FadeImage';
import ViewFadeGroup from './Fade/FadeGroup';

const ImageCardWithDescription = props => {
  const {
    title,
    titleCustom,
    description,
    image,
    videoFile,
    videoLoop = true,
    imageWidth = '450',
    imageStyle,
    textRight,
    className,
    imageClassName,
    customGroup,
  } = props;
  const [visible, setVisible] = useState(false);
  const flowClass = textRight ? 'flex-row-reverse' : '';
  const dir = textRight ? 'right' : 'left';
  const dirImage = textRight ? 'left' : 'right';
  return (
    <div
      className={`d-md-flex align-items-center justify-content-between ${flowClass} ${className ||
        ''}`}
    >
      <div>
        <div style={{ maxWidth: 650 }} className="mt-4">
          <ViewFadeGroup
            dir={dir}
            visible={visible}
            onEnterViewport={() => setVisible(true)}
            onLeaveViewport={() => setVisible(true)}
          >
            {titleCustom ? (
              titleCustom
            ) : (
              <div className="font-weight-bold shadow-blue rounded p-3 text-white bg-dark">
                <span title={title}>{title}</span>
              </div>
            )}
          </ViewFadeGroup>
          <br />
          <ViewFadeGroup dir={dir}>
            <div className="text-light">{description}</div>
          </ViewFadeGroup>
        </div>
      </div>
      {customGroup && (
        <div className={imageClassName}>
          <ViewFadeGroup
            visible={visible}
            dir={dirImage}
            className="d-flex justify-content-center"
          >
            {customGroup}
          </ViewFadeGroup>
        </div>
      )}
      {!customGroup && (
        <div className={imageClassName}>
          {videoFile ? (
            <ViewFadeGroup
              visible={visible}
              dir={dirImage}
              className="d-flex justify-content-center"
            >
              <video
                autoPlay
                loop={videoLoop}
                width={imageWidth.toString()}
                className={`card-video`}
                playsInline={true}
                muted={true}
              >
                <source src={videoFile} type="video/mp4" />
              </video>
            </ViewFadeGroup>
          ) : (
            <ViewFadeImage
              src={image}
              visible={visible}
              original
              dir={dirImage}
              width={imageWidth.toString()}
              style={imageStyle}
              className="img-fluid"
            />
          )}
        </div>
      )}
    </div>
  );
};

export default ImageCardWithDescription;
