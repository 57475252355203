import React from 'react';

import { Navbar, Nav, Collapse } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faBars,
  faChevronDown,
  faChevronUp,
  faPhone,
} from '@fortawesome/free-solid-svg-icons';
import logoNewtop from '../../assets/images/logo.png';
import PromoBar from './PromoBar';
import { makeDashboardUri } from '../../utils';

class Header extends React.PureComponent {
  state = {
    menuOpen: false,
    toggle: false,
    hash: window.location.hash,
    showSubMenu: true,
  };

  isDashboardLive = () => !!this.props.isDashboardLive;

  componentDidMount() {
    window.onhashchange = this.onHashChange;
  }

  componentWillUnmount() {
    window.onhashchange = null;
  }

  onHashChange = () => {
    this.setState({ hash: window.location.hash });
  };

  toggleNavbar = () => {
    this.setState(prevProps => ({ menuOpen: !prevProps.menuOpen }));
  };

  hasAuthenticated = () =>
    localStorage.getItem('accounts:hasAuthenticated') === 'true';

  goToDashboard = () => {
    const dashboardUri = makeDashboardUri(window.origin);
    // call www. to avoid 301 redirect from naked S3 bucket
    if (!this.hasAuthenticated()) {
      window.location = `${dashboardUri}cryptocurrency-insurance-quick-quote`;
      return;
    }
    window.location = `${dashboardUri}`;
  };

  getNavLinkDrawerClass = (path, hash) => {
    const currPath = window.location.pathname;
    const currHash = this.state.hash;
    if (currHash || hash) {
      if (path === currPath && hash === currHash) {
        return 'text-info';
      }
      return 'text-dark';
    }

    return path === currPath ? 'text-info' : 'text-dark';
  };

  getNavLinkClass = (path, hash) => {
    const currPath = window.location.pathname;
    const currHash = this.state.hash;
    if (currHash || hash) {
      if (path === currPath && hash === currHash) {
        return 'nav-link text-info text-nowrap';
      }
      return 'nav-link';
    }

    return path === currPath ? 'nav-link text-info text-nowrap' : 'nav-link';
  };

  getNavItemClass = (path, pattern = false) => {
    const currPath = window.location.pathname;
    const { hash } = this.state;
    if (Array.isArray(pattern)) {
      return pattern.includes(`${currPath}${hash}`)
        ? 'nav-item active'
        : 'nav-item';
    }
    if (pattern) {
      return currPath.includes(path) ? 'nav-item active' : 'nav-item';
    }
    return path === currPath ? 'nav-item active' : 'nav-item';
  };

  renderDrawer = () => (
    <Navbar.Collapse id="basic-navbar-nav">
      {/*
            <Form inline>
              <FormControl
                type="text"
                placeholder="Search"
                className="mr-sm-2 p-2 mt-3"
              />
            </Form>
            */}
      <Nav className="mr-auto p-3">
        <div className="text-right">
          <FontAwesomeIcon
            icon={faPhone}
            className="ml-1 mr-1"
            title="(802) 357-3224"
          />
          (802) 357-3224
        </div>
        {/* {this.isDashboardLive() && (
          <Nav.Link
            href="#dashboad"
            className="text-dark"
            onClick={this.goToDashboard}
          >
            Dashboard
          </Nav.Link>
        )} */}
        <Nav.Link href="/" className={this.getNavLinkDrawerClass('/')}>
          Home
        </Nav.Link>

        <div className="d-flex align-items-center">
          {' '}
          <Nav.Link
            href="/services"
            className={this.getNavLinkDrawerClass('/services')}
          >
            Who We Serve
          </Nav.Link>
          <FontAwesomeIcon
            className={`ml-2 mouse-pointer ${this.getNavLinkDrawerClass(
              '/services',
            )}`}
            onClick={() =>
              this.setState(prev => ({ showSubMenu: !prev.showSubMenu }))
            }
            icon={this.state.showSubMenu ? faChevronUp : faChevronDown}
          />
        </div>

        <Collapse in={this.state.showSubMenu}>
          <div className="ml-3 sub-menu-drawer">
            <Nav.Link
              href="/cryptoshieldpro"
              className={this.getNavLinkDrawerClass('/cryptoshieldpro')}
            >
              Institutions
            </Nav.Link>
            <Nav.Link
              href="/cryptoshield"
              className={this.getNavLinkDrawerClass('/cryptoshield')}
            >
              Individuals
            </Nav.Link>
            <Nav.Link
              href="/smb"
              className={this.getNavLinkDrawerClass('/smb')}
            >
              Startup &amp; SMBs
            </Nav.Link>
          </div>
        </Collapse>

        <div className="d-flex align-items-center">
          <Nav.Link
            href="/cryptoshield#intro"
            className={this.getNavLinkDrawerClass('/notapplicable')}
          >
            Our Products
          </Nav.Link>
          <FontAwesomeIcon
            className={`ml-2 mouse-pointer ${this.getNavLinkDrawerClass(
              '/notapplicable',
            )}`}
            onClick={() =>
              this.setState(prev => ({ showSubMenu: !prev.showSubMenu }))
            }
            icon={this.state.showSubMenu ? faChevronUp : faChevronDown}
          />
        </div>

        <Collapse in={this.state.showSubMenu}>
          <div className="ml-3 sub-menu-drawer">
            <Nav.Link
              href="/cryptoshield#intro"
              className={this.getNavLinkDrawerClass('/cryptoshield', '#intro')}
            >
              Crypto Shield
            </Nav.Link>
            <Nav.Link
              href="/cryptoshieldpro#intro"
              className={this.getNavLinkDrawerClass(
                '/cryptoshieldpro',
                '#intro',
              )}
            >
              Crypto Shield<sup className="text-info">Pro</sup>
            </Nav.Link>
            <Nav.Link
              href="/cryptoshieldproplatforms"
              className={this.getNavLinkDrawerClass(
                '/cryptoshieldproplatforms',
              )}
            >
              Crypto Shield<sup className="text-info">Pro</sup> for Platforms
            </Nav.Link>
            <Nav.Link
              href="/cyber-insurance"
              className={this.getNavLinkDrawerClass('/cyber-insurance')}
            >
              Cyber
            </Nav.Link>
            <Nav.Link
              href="/directors-officers"
              className={this.getNavLinkDrawerClass('/directors-officers')}
            >
              Directors &amp; Officers
            </Nav.Link>

            <Nav.Link
              href="/smb#errors"
              className={this.getNavLinkDrawerClass('/smb', '#errors')}
            >
              Errors &amp; Omissions
            </Nav.Link>
            <Nav.Link
              href="/smb#general"
              className={this.getNavLinkDrawerClass('/smb', '#general')}
            >
              General Liability
            </Nav.Link>
            <Nav.Link
              href="/wallet-assessment"
              className={this.getNavLinkDrawerClass('/wallet-assessment')}
            >
              Wallet Assessment
            </Nav.Link>
          </div>
        </Collapse>

        <Nav.Link
          href="/about-us"
          className={this.getNavLinkDrawerClass('/about-us')}
        >
          About Us
        </Nav.Link>
        <Nav.Link
          className={this.getNavLinkDrawerClass('/blogs', true)}
          href="/blogs"
        >
          Blogs
        </Nav.Link>
        {/* <Nav.Link
          className={this.getNavLinkDrawerClass('/partners', true)}
          href="/partners"
        >
          Partners
        </Nav.Link> */}
        <Nav.Link href="/#contact-us" className="text-dark">
          Contact Us
        </Nav.Link>

        {/*
              <Nav.Link href="#link" className="text-light font-weight-bold">
                Login/Register
              </Nav.Link>
              */}
      </Nav>
    </Navbar.Collapse>
  );

  renderNav = () => {
    const { hideMenu } = this.props;
    if (hideMenu) {
      return (
        <nav className={`navbar navbar-expand-md  d-none d-md-flex`}>
          <div className="container" style={{ paddingRight: 20 }}>
            <div>
              <img
                src={logoNewtop}
                // width="200"
                style={{ maxWidth: 200, marginLeft: 1 }}
                alt="logo"
              />
            </div>
            <div className="text-right">
              <Nav.Link href="#contact-us" className="nav-btn">
                Contact Us
              </Nav.Link>
            </div>
          </div>
        </nav>
      );
    }

    return (
      <nav
        className={`navbar navbar-expand-md  d-none d-md-flex px-0 justify-content-end`}
      >
        <div className="container" style={{ paddingRight: 20 }}>
          <button
            className="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="/navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon " />
          </button>
          <div>
            <a className=" d-lg-inline" href="/#home">
              <img src={logoNewtop} className="header-logo" alt="logo" />
            </a>
          </div>
          <div
            className="d-flex justify-content-end pt-1 collapse navbar-collapse bs-navbar-collapse pl-0"
            id="navigation"
          >
            <ul className="header-menu nav navbar-nav justify-content-end">
              <li className={this.getNavItemClass('/')}>
                <a className="nav-link pl-0" href="/#home">
                  Home
                </a>
              </li>
              {/* <li className="nav-item">
            <a className="nav-link" href="/#features">
              Features
            </a>
          </li> */}
              {/* <li className="nav-item">
              <a className="nav-link" href="/#partners">
                Partners
              </a>
            </li> */}
              {/* <li className="nav-item">
              <a className="nav-link" href="/faq">
                FAQ
              </a>
            </li> */}
              <li
                className={`${this.getNavItemClass('/services', [
                  '/cryptoshieldpro',
                  '/cryptoshield',
                  '/smb',
                  '/services',
                ])} sub-menu-container`}
              >
                <a className="nav-link" href="/services">
                  Who We Serve &nbsp;
                  <FontAwesomeIcon icon={faChevronDown} />
                </a>
                <ul
                  className="sub-menu"
                  style={{
                    listStyleType: 'none',
                    paddingLeft: 0,
                    paddingBottom: 10,
                    paddingTop: 10,
                  }}
                >
                  <li>
                    <a
                      className={this.getNavLinkClass('/cryptoshieldpro')}
                      href="/cryptoshieldpro"
                    >
                      Institutions
                    </a>
                  </li>
                  <li>
                    <a
                      className={this.getNavLinkClass('/cryptoshield')}
                      href="/cryptoshield"
                    >
                      Individuals
                    </a>
                  </li>
                  <li>
                    <a className={this.getNavLinkClass('/smb')} href="/smb">
                      Startups &amp; SMBs
                    </a>
                  </li>
                </ul>
              </li>
              <li
                className={`${this.getNavItemClass('/', [
                  '/cryptoshield#intro',
                  '/cryptoshieldpro#intro',
                  '/cyber-insurance',
                  '/smb#general',
                  '/smb#errors',
                  '/directors-officers',
                  '/wallet-assessment',
                  '/cryptoshieldproplatforms',
                ])} sub-menu-container`}
              >
                <a className="nav-link noselect" href="/cyber-insurance">
                  <span className="d-none d-lg-inline">Our</span> Products&nbsp;
                  <FontAwesomeIcon icon={faChevronDown} />
                </a>
                <ul
                  className="sub-menu"
                  style={{
                    listStyleType: 'none',
                    paddingLeft: 0,
                    paddingBottom: 10,
                    paddingTop: 10,
                  }}
                >
                  <li>
                    <a
                      className={this.getNavLinkClass(
                        '/cryptoshield',
                        '#intro',
                      )}
                      href="/cryptoshield#intro"
                    >
                      Crypto Shield
                    </a>
                  </li>
                  <li>
                    <a
                      className={this.getNavLinkClass(
                        '/cryptoshieldpro',
                        '#intro',
                      )}
                      href="/cryptoshieldpro#intro"
                    >
                      Crypto Shield<sup className="text-info">Pro</sup>
                    </a>
                  </li>
                  <li>
                    <a
                      className={this.getNavLinkClass(
                        '/cryptoshieldproplatforms',
                      )}
                      href="/cryptoshieldproplatforms"
                    >
                      Crypto Shield<sup className="text-info">Pro</sup> for
                      Platforms
                    </a>
                  </li>
                  <li>
                    <a
                      className={this.getNavLinkClass('/cyber-insurance')}
                      href="/cyber-insurance"
                    >
                      Cyber
                    </a>
                  </li>
                  <li>
                    <a
                      className={this.getNavLinkClass('/directors-officers')}
                      href="/directors-officers"
                    >
                      Directors &amp; Officers
                    </a>
                  </li>

                  <li>
                    <a
                      className={this.getNavLinkClass('/smb', '#errors')}
                      href="/smb#errors"
                    >
                      Errors &amp; Omissions
                    </a>
                  </li>
                  <li>
                    <a
                      className={this.getNavLinkClass('/smb', '#general')}
                      href="/smb#general"
                    >
                      General Liability
                    </a>
                  </li>
                  <li>
                    <a
                      className={this.getNavLinkClass('/wallet-assessment')}
                      href="/wallet-assessment"
                    >
                      Wallet Risk Assessment
                    </a>
                  </li>
                </ul>
              </li>

              <li className={this.getNavItemClass('/about-us', true)}>
                <a className="nav-link" href="/about-us">
                  About Us
                </a>
              </li>

              <li className={this.getNavItemClass('/blogs', true)}>
                <a className="nav-link" href="/blogs">
                  Blogs
                </a>
              </li>
              {/* <li className={this.getNavItemClass('/partners')}>
              <a className="nav-link" href="/partners">
                Partners
              </a>
            </li> */}
            </ul>
            <div className="text-right  d-none d-lg-block">
              <Nav.Link href="/#contact-us" className="nav-btn">
                <span>Contact Us</span>
              </Nav.Link>
            </div>
            <div className="text-right d-block d-lg-none">
              <Nav.Link href="/#contact-us" className="nav-btn p-2">
                <FontAwesomeIcon icon={faPhone} />
              </Nav.Link>
            </div>
          </div>
        </div>
      </nav>
    );
  };

  render() {
    const { promoText, hideMenu } = this.props;
    const { menuOpen } = this.state;
    if (hideMenu) {
      return (
        <div>
          <Navbar
            className={`navbar ${
              menuOpen ? 'open' : ''
            }  navbar-expand-lg navbar-dark d-flex d-md-none`}
            expand="lg"
          >
            <Navbar.Brand href="/#home" className="mx-auto">
              <img src={logoNewtop} width="200" alt="logo" />
            </Navbar.Brand>
          </Navbar>

          {this.renderNav()}
        </div>
      );
    }
    return (
      <>
        {promoText && <PromoBar value={promoText} />}
        <div>
          <Navbar
            className={`navbar ${
              menuOpen ? 'open' : ''
            }  navbar-expand-lg navbar-dark d-flex d-md-none`}
            expand="lg"
          >
            <Navbar.Brand href="/#home" className="mx-auto">
              <img src={logoNewtop} width="200" alt="logo" />
            </Navbar.Brand>
            <div className="mr-5">
              <Navbar.Toggle onClick={this.toggleNavbar}>
                <FontAwesomeIcon icon={faBars} />
              </Navbar.Toggle>
            </div>

            {this.renderDrawer()}
          </Navbar>

          {this.renderNav()}
        </div>
      </>
    );
  }
}

export default Header;
