import React from 'react';

const ProductCard = ({
  className,
  icon,
  iconWidth = 40,
  titleClass,
  titleIcon,
  title,
  onClick,
  description,
  actionTitle = 'Get a Quote',
}) => {
  return (
    <div
      className={`d-flex flex-column bg-dark rounded-xl p-4 text-white mb-3 ${className ||
        ''}`}
    >
      <div className="d-flex justify-content-between">
        <h4 className={titleClass}>{title}</h4>
        <img
          alt="Title Icon"
          src={titleIcon}
          width={160}
          style={{ marginLeft: 10, marginTop: -10 }}
        />
      </div>
      <img className="mt-1 mb-3" alt="icon" src={icon} width={iconWidth} />
      <div className="d-flex flex-column flex-fill align-items-baseline">
        <div style={{ fontSize: 12 }} className="flex-fill">
          {description ? (
            description
          ) : (
            <>
              We understand startup and small business risks because we’ve been
              there. Whether it’s cyber, GL, E&amp;O or other policies, we
              ensure you get the best product(s) for your risk profile by
              offering a smooth underwriting application and efficient
              purchasing process
            </>
          )}
        </div>
        <button
          className="btn btn-primary mt-3"
          onClick={onClick}
          style={{ minWidth: 200 }}
        >
          {actionTitle}
        </button>
      </div>
    </div>
  );
};

export default ProductCard;
