import React from 'react';

const PrivacyPolicyBody = () => (
  <div className="mt-5">
    <br />
    <div className="text-center">
      <h4>Breach Insurance Solutions, Inc Privacy Policy</h4>
      <i>Effective Date: November 17, 2021</i>
      <br />
      <i>Last Updated: November 17, 2021</i>
    </div>
    <hr className="solid" />
    <p className="m-0">
      This Privacy Policy (the “Policy”) applies to the websites, applications,
      and electronic communications on which it appears or is linked (the
      “Platform”). This Policy describes how Breach Insurance Solutions, Inc as
      well as our affiliates and subsidiaries (collectively ”Breach Insurance
      Solutions, Inc” or ”we” or ”us” or ”our”) uses the Personal Information
      that we collect, receive, maintain, and store about you. Please read this
      Policy carefully, by interacting with us through the Platform you consent
      to this Policy. Note: This Policy does not apply to information subject to
      the Gramm-Leach-Bliley Act (GLBA). To see our GLBA
      <br />
      <br />
      Privacy Notice please visit&nbsp;
      <a
        className="text-link"
        target="_blank"
        rel="noopener noreferrer"
        href="https://www.dev1.breach.insure/notices/Gramm_Doc.pdf"
      >
        here.
      </a>
    </p>
    <br />
    <div>
      <p>
        <span className="border-bottom border-dark">
          <b>1. Types of Personal Information Collected</b>
        </span>
      </p>
      <p className="ml-4 mr-4">
        We collect a range of Personal Information. “Personal Information” is
        information that identifies, relates to, describes, or is reasonably
        capable of being associated with or linked to an individual. When we
        combine Personal Information with other information (i.e. information
        that does not, on its own, identify you), we treat the combined
        information as Personal Information.
        <br />
        <br />
        <b>Contact and Demographic Information –</b> If you register for an
        account or submit an inquiry, we may collect your contact and mailing
        information. This may include your email, name, address, phone number,
        and pet name. In addition, from time to time we receive email lists from
        our channel partners, which may also include the contact and demographic
        information listed above.
        <br />
        <br />
        <b>Payment Information – </b> If you make a purchase through the
        Platform, we will collect your payment information including your credit
        card information and billing address. We will also collect your purchase
        details.
        <br />
        <br />
        <b>Employment Information –</b> If you apply for employment with Breach
        Insurance Solutions, Inc, we will collect your employment and work
        history, as well as Personal Information related to your potential
        employment. This may include your education and employment history,
        address and contact information, demographic information, and any other
        information included in your resume or application.
        <br />
        <br />
        <b>Account Information –</b> If you create an account with us, you will
        create and provide us with a user name and password, and may upload
        other Personal Information through our customer portal. We may also
        collect your purchase information.
        <br />
        <br />
        <b>Location Data –</b> While navigating our Platform your mobile device
        or browser may share you’re your location data, both through WiFi and
        GPS. Breach Insurance Solutions, Inc will collect this information based
        on the settings of your phone and browser.
        <br />
        <br />
        <b>Usage Information –</b> We may also collect usage and device
        information when you visit our Platform. This may include information
        about the browser, operating system, and/or device identifier for the
        device you are using, your ISP and IP information, your navigation
        activity on the Platform, as well as the websites that you came from and
        the site you visit when you leave.
      </p>
    </div>
    <div>
      <p>
        <span className="border-bottom border-dark">
          <b>2. Method of Information Collection</b>
        </span>
      </p>
      <p className="ml-4 mr-4">
        We collect Personal Information in a variety of ways. We collect
        Personal Information directly from you; for example, when you register
        an account on the Platform, make a purchase, submit a job application,
        or correspond with us through the Platform. <br />
        <br /> We also collect information from third parties. For example, we
        work with business partners, subcontractors in technical and delivery
        services, advertising networks, analytics providers, and search
        information providers. We also collect Personal Information from you
        passively. Our Platform uses tracking tools like cookies, pixels, and
        web beacons. We do this both on our Platform and in emails that we send
        to you. Through these tools, we collect Personal Information about users
        over time when you use our Platform. This collection includes usage and
        browser information. We may also have third parties collect Personal
        Information in this way. For more information about the trackers that we
        use, please see Section 6 of this Policy. Additionally, if you enable
        location data on your device.
      </p>
    </div>
    <div>
      <p>
        <span className="border-bottom border-dark">
          <b>3. Combining Information</b>
        </span>
      </p>
      <p className="ml-4 mr-4">
        We may combine Personal Information we obtain. For example, we may
        combine Personal Information that we have collected online with Personal
        Information that we have collected offline, for instance during calls
        between you and our support team. We may also combine Personal
        Information that we have collected across other third party sites or
        from other third parties with Personal Information we already have.
        Finally, we combine Personal Information across devices.
      </p>
    </div>
    <div>
      <p>
        <span className="border-bottom border-dark">
          <b>4. How We Use Personal Information</b>
        </span>
      </p>
      <p className="ml-4 mr-4">
        We will use your Personal Information to fulfill purchases and offer our
        services, respond to your requests and inquiries, as well as to send you
        communications. We also use your Personal Information to communicate
        with you for transactional purposes. For example, we might contact you
        about this Policy or our Terms of Use.
        <br />
        <br />
        We may also use your Personal Information to improve our Platform,
        products, and services. This may include using your Personal Information
        to customize your experience with us.
        <br />
        <br />
        We may also use your Personal Information for the security of the
        Platform and our company, our customers, and other third parties. We may
        also use your Personal Information to protect against fraud and other
        malicious activity. We may also use your Personal Information for
        marketing purposes. For example, we may send you information about new
        features on the Platform, new services we offer, and special offers.
        When legally permitted, this may also include using your Personal
        Information to serve you ads and to tell you about products or offers
        available through us, or third parties that we think you might find
        interesting.
        <br />
        <br />
        Finally, we may use your Personal Information for other purposes, as
        permitted by law.
      </p>
    </div>
    <div>
      <p>
        <span className="border-bottom border-dark">
          <b>5. Sharing Personal Information</b>
        </span>
      </p>
      <p className="ml-4 mr-4">
        We may share your Personal Information in the following circumstances:
        <br />
        <br />
        <b>Within Breach Insurance Solutions, Inc – </b> We may share your
        Personal Information within our family of companies.
        <br />
        <br />
        <b>Service providers – </b> When we hire a service provider to help
        operate the Platform or our business, we may give access to Personal
        Information as necessary to perform the service for Breach Insurance
        Solutions, Inc. This may include, but is not limited to, service
        providers that operate our Platform, send our communications, or run our
        promotions.
        <br />
        <br />
        <b>Business Partners – </b> Breach Insurance Solutions, Inc may share
        Personal Information to our trusted business partners. For example, we
        may share your Personal Information with a company when we co-sponsor a
        promotion or service. These partners may use your Personal Information
        to, among other things, send you information or contact you about their
        services and products
        <br />
        <br />
        <b>Third Parties for Marketing Purposes –</b> We do not currently share
        your Personal Information with third parties for their marketing
        purposes.
        <br />
        <br />
        Legal obligation or protection from harm - When we have a good faith
        belief that access, use, preservation or disclosure of the Personal
        Information is reasonably necessary to (a) satisfy or comply with any
        requirement of law, regulation, legal process, or enforceable
        governmental request, (b) enforce or investigate a potential violation
        of the Terms of Use, (c) detect, prevent, or otherwise respond to fraud,
        security or technical concerns, (d) support auditing and compliance
        functions, or (e) protect the rights, property, or safety of Breach
        Insurance Solutions, Inc, its users, or the public against harm.
        <br />
        <br />
        <b>Merger or sale -</b> If and when Breach Insurance Solutions, Inc is
        involved in a merger, acquisition, or any form of transfer or sale of
        some or all of its business, whether as a going concern or as part of
        bankruptcy, liquidation, or similar proceeding. Personal Information may
        be transferred along with the business. Where legally required we will
        give you prior notice and if you have a legal right to do so, an
        opportunity to object to this transfer.
        <br />
        <br />
        <b>Purposes described to you – </b> We may share your Personal
        Information for other purposes, as we describe to you.
      </p>
    </div>
    <div>
      <p>
        <span className="border-bottom border-dark">
          <b>6. Cookies and Other Technologies</b>
        </span>
      </p>
      <p className="ml-4 mr-4">
        We collect Personal Information about users over time on the Platform by
        using several common types of cookies and other tracking technologies
        including web beacons, pixels, and flash cookies. We also have third
        parties that collect Personal Information in this way on our behalf and
        for their own purposes. Cookies are small files that download when you
        access certain websites. For more information about cookies visit:
        <a
          className="text-link"
          target="_blank"
          rel="noopener noreferrer"
          href="https://www.allaboutcookies.org"
        >
          http://www.allaboutcookies.org/
        </a>
        . Web beacons, pixels, and other tracking technologies operate
        independent of cookies.
        <br />
        <br />
        To assist us with analyzing our website traffic through cookies and
        similar technologies, we use analytics services such as Google
        Analytics. For more information on Google Analytics’ processing of your
        Personal Information, please see{' '}
        <a
          className="text-link"
          target="_blank"
          rel="noopener noreferrer"
          href="https://policies.google.com"
        >
          “How Google uses data when you use our partners&apos; Platforms or
          apps.”{' '}
        </a>
        You can opt out of Google Analytics by installing{' '}
        <a
          className="text-link"
          target="_blank"
          rel="noopener noreferrer"
          href={`https://www.bing.com/search?q=google+opt+out+browser+add+on&cvid=f4d45467975d4c5b81020df6481807ac&aqs=edge.2.69i57j0l2.12263j0j4&FORM=ANAB01&PC=ACTS`}
        >
          Google’s opt-out browser add-on.
        </a>
        <br />
        <br />
        These cookies may be ones placed by us (first party) or by a third
        party. These cookies may also be Flash Cookies. To learn how to manage
        privacy and storage settings for Flash cookies click here. We may use
        cookies that are session-based or persistent. Session cookies expire
        when you close your browser or turn off your device. Persistent cookies
        remain on your device after you close your browser or turn off device.
        <br />
        <br />
        We may use a variety of cookies:
      </p>
      <div className="ml-4 mr-4">
        <table className="table table-bordered mt-1">
          <thead>
            <tr>
              <th className="text-truncate">Type of Cookies</th>
              <th>Description</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Required</td>
              <td>
                Required cookies are essential for the Platform to function
                properly and for you to navigate our Site and use its features,
                such as accessing secure areas of the Platform and using our
                services.
              </td>
            </tr>
            <tr>
              <td>Performance</td>
              <td>
                These cookies collect information about how you use the
                Platform, including which pages you go to most often and if they
                receive error messages from certain pages. These cookies do not
                collect information that individually identifies you. All
                information these cookies collect is aggregated and anonymous.
                It is only used to improve how the Platform functions and
                performs.
              </td>
            </tr>
            <tr>
              <td>Functionality</td>
              <td>
                Functionality cookies allow the Platform to remember information
                you have entered or choices you make (such as your username,
                language, or your region) and provide enhanced, more personal
                features. These cookies also enable you to optimize your use of
                the Platform after logging in. These cookies can also be used to
                remember changes you have made to text size, fonts and other
                parts of web pages that you can customize.
              </td>
            </tr>
            <tr>
              <td>Targeting or Information</td>
              <td>
                From time-to-time, we may engage third parties to track and
                analyze usage and volume statistical information from
                individuals who visit the Platform. We sometimes use cookies
                delivered by third parties to track the performance of our
                advertisements. For example, these cookies remember which
                browsers have visited the Platform. The information provided to
                third parties does not include Personal Information, but this
                information may be re-associated with Personal Information after
                we receive it. <br />
                <br /> By way of example, as you visit the Platform, advertising
                cookies such as Google AdWords, may be placed on your computer
                so that we can understand what you are interested in. Our
                advertising partners then enable us to present you with
                retargeted advertising on other sites based on your previous
                interaction with the Platform. <br />
                <br />
                We also contract with third-party advertising networks and
                similar partners that collect IP addresses and other information
                on our Platform, from emails, and on third-party sites. Ad
                networks follow your online activities over time by collecting
                Site Navigational Information through automated means, including
                through the use of cookies. They use this information to provide
                advertisements about products and services tailored to your
                interests. You may see these advertisements on other websites.
                This process also helps us manage and track the effectiveness of
                our marketing efforts. <br />
                <br />
                Third parties, with whom we partner to provide certain features
                on the Platform or to display advertising based upon your web
                browsing activity, use Flash cookies to collect and store
                information. Flash cookies are different from browser cookies
                because of the amount of, type of, and how data is stored.
              </td>
            </tr>
          </tbody>
        </table>
        <p className="ml-4 mr-4">
          You can control cookies and tracking tools. Some browsers have a “do
          not track” feature that prevents a website from tracking you, as well
          as additional cookie settings. Our Platform does not currently respond
          to these signals. If you choose to block cookies and other trackers,
          certain features of the Platform may not work. Blocking or rejecting
          cookies will not stop all of the tracking described in this Policy.
          These features and options are browser and device specific and are not
          uniform. You can usually find these settings in the options or
          preferences menu of your browser. To understand these settings, the
          following links for commonly used browsers may be helpful, or you can
          use the help option in your browser for more details:
        </p>
        <ul>
          <li>
            <a
              className="text-link"
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.techbout.com/enable-disable-cookies-internet-explorer-6778/#:~:text=Disable%20Cookies%20on%20Internet%20Explorer.%201%201.%20Click,for%20both%20First-party%20and%20Third-party%20Cookies.%20More%20items"
            >
              Cookie settings in Internet Explorer
            </a>
          </li>
          <li>
            <a
              className="text-link"
              target="_blank"
              rel="noopener noreferrer"
              href="https://support.mozilla.org/en-US/kb/cookies-information-websites-store-on-your-computer#:~:text=1%20Click%20the%20menu%20button%20and%20select%20Options.,Select%20the%20Privacy%20%26%20Security%20panel.%20See%20More"
            >
              Cookie settings in Firefox
            </a>
          </li>
          <li>
            <a
              className="text-link"
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.whatismybrowser.com/guides/how-to-enable-cookies/chrome#:~:text=%20Enable%20Cookies%20in%20Chrome%20%201%20Click,search%20box%20at%20the%20very%20top.%20More%20"
            >
              Cookie settings in Chrome
            </a>
          </li>
          <li>
            <a
              className="text-link"
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.whatismybrowser.com/guides/how-to-enable-cookies/safari-iphone#:~:text=Scroll%20and%20tap%20on%20the%20%22Safari%22%20item%20The,Safari.%20Scroll%20down%20and%20choose%20your%20Cookies%20preference"
            >
              Cookie settings in Safari web and iOS
            </a>
          </li>
        </ul>
        <p>
          To find out more on how to manage and delete cookies, visit
          aboutcookies.org. For more details on your choices regarding use of
          your web browsing activity for interest-based advertising you may
          visit the following sites:
        </p>
        <ul>
          <li>
            <a
              className="text-link"
              target="_blank"
              rel="noopener noreferrer"
              href="https://networkadvertising.org"
            >
              networkadvertising.org
            </a>
          </li>
          <li>
            <a
              className="text-link"
              target="_blank"
              rel="noopener noreferrer"
              href="https://optout.aboutads.info"
            >
              optout.aboutads.info
            </a>
          </li>
          <li>
            <a
              className="text-link"
              target="_blank"
              rel="noopener noreferrer"
              href="https://youradchoices.com"
            >
              youradchoices.com
            </a>
          </li>
          <li>
            <a
              className="text-link"
              target="_blank"
              rel="noopener noreferrer"
              href="https://youronlinechoices.eu"
            >
              youronlinechoices.eu
            </a>
          </li>
        </ul>
        <p>
          On a mobile device, you may also be to adjust your settings to limit
          ad tracking.
        </p>
      </div>
    </div>
    <div>
      <p>
        <span className="border-bottom border-dark">
          <b>7. Your Choices</b>
        </span>
      </p>
      <p className="ml-4 mr-4">
        You have certain choices about how we use your Personal Information. You
        can opt out-out of certain marketing. To opt-out of marketing
        communications, please email us at privacy@breachinsured.com
        <br />
        <br />
        or by following the instructions included in the email or text
        correspondence. Please note that, even if you unsubscribe from certain
        correspondences, we may still need to contact you with important
        transactional or administrative information, as permitted by law.
      </p>
    </div>
    <div>
      <p>
        <span className="border-bottom border-dark">
          <b>8. Children Under the Age of 13</b>
        </span>
      </p>
      <p className="ml-4 mr-4">
        The Platform is meant for adults and we will not knowingly collect
        Personal Information from any person under the age of 13 without
        permission from a parent or guardian. The Platform is not designed to
        attract the attention of persons under the age of 13. If you are a
        parent or legal guardian and think your child has given us Personal
        Information you can email us at privacy@breachinsured.com. You can also
        write to us at the address listed in the “Contact Us” section of this
        Policy. Please mark your inquiries “COPPA Inquiry.”
      </p>
    </div>
    <div>
      <p>
        <span className="border-bottom border-dark">
          <b>9. California Privacy Rights</b>
        </span>
      </p>
      <p className="ml-4 mr-4">
        Pursuant to California’s Shine the Light statute (Cal. Civ. Code Sec.
        1798.83), you can control if we share Personal Information with third
        parties for their marketing purposes. To opt-out of us sharing your
        <br />
        <br />
        Personal Information with third parties for such promotional purposes,
        email us at privacy@breachinsured.com or write to us in the “Contact Us”
        section of this Policy and include “Marketing Opt-Out” in your request.
        <br />
        <br />
        In addition, pursuant to the California Consumer Privacy Act of 2018
        (“CCPA”), California residents have certain other rights concerning
        their Personal Information. This section describes (1) the categories of
        Personal Information, collected and disclosed by Breach Insurance
        Solutions, Inc, subject to CCPA, (2) your privacy rights under CCPA, and
        (3) how to exercise those rights.
        <br />
        <br />
        When we use the term “Personal Information” in this section, we mean
        information that identifies, relates to, describes, is capable of being
        associated with, or could reasonably be linked, directly or indirectly,
        with a particular consumer or household.
        <br />
        <br />
        <i>a. Personal Information We Collect</i>
        <br />
        <br />
        If you are a California resident, please be aware that we collect,
        process, and maintain Personal Information to fulfill certain business
        purposes related to our Platform. We will not collect additional
        categories of Personal Information or use Personal Information collected
        for additional purposes without providing you notice. Within the
        preceding twelve (12) months we and our service providers have
        collected, and we may continue to collect, the categories of Personal
        Information set forth below. We disclose each of these categories of
        Personal Information to our service providers for our business purposes
        (to enable the service providers to provide their services) and as
        otherwise described in the “How We Disclose Personal Information”
        section above. Additionally, some of our online advertisers may have
        used and disclosed Usage Information (as defined below) collected
        automatically from the Platform. Regardless of whether this a “sale” of
        Personal Information as defined by CCPA, we provide you the right to opt
        out of cookies used for online behavioral advertising as described
        above.
      </p>
      <ul className="ml-5 mr-5">
        <li>
          Identifiers (for example, name, postal address, date of birth, email
          address, IP address, and online identifiers);
        </li>
        <li>
          Categories of Personal Information as defined by Cal. Civ. Code §
          1798.80 (for example, name, signature, Taxpayer Identification Number,
          telephone number, passport number, driver’s license number, insurance
          policy number, account numbers);
        </li>
        <li>
          Protected classifications under California or federal law (for
          example, age, citizenship status, national origin or ancestry);
        </li>
        <li>Commercial information (for example, transaction history);</li>
        <li>
          Internet or other electronic network activity information, including
          information on your usage of our Sites (“Usage Information”);
        </li>
        <li>Geolocation data;</li>
        <li>Sensory data (for example, audio from call recordings); and</li>
        <li>
          Inferences drawn from any information identified above to create a
          profile.
        </li>
      </ul>
      <p className="ml-4 mr-4">
        <i>b. Your Privacy Rights.</i>
        <br />
        <br />
        In accordance with the CCPA, California residents may exercise under
        certain conditions the following four (4) categories privacy rights with
        respect to their Personal Information. Please note: you will not be
        discriminated against in any way by virtue of your exercise of the
        rights listed below, which means we will not deny goods or services to
        you, provide a different prices or rates for goods or services to you,
        or provide a different level or quality of goods or services to you.
      </p>
      <div className="ml-4 mr-4">
        <table className="table table-bordered mt-1 ">
          <thead>
            <tr>
              <th className="text-truncate">Privacy Right</th>
              <th>Description</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Notice</td>
              <td>
                You may have the right to be notified of what categories of
                Personal Information will be collected at or before the point of
                collection and the purposes for which they will be used and
                shared.
              </td>
            </tr>
            <tr>
              <td>Performance</td>
              <td>
                These cookies collect information about how you use the
                Platform, including which pages you go to most often and if they
                receive error messages from certain pages. These cookies do not
                collect information that individually identifies you. All
                information these cookies collect is aggregated and anonymous.
                It is only used to improve how the Platform functions and
                performs.
              </td>
            </tr>
            <tr>
              <td>Access</td>
              <td>
                You may have the right to request the categories of Personal
                Information that we collected in the previous twelve (12)
                months, the categories of sources from which the Personal
                Information was collected, the specific pieces of Personal
                Information we have collected about you, and the business
                purposes for which such Personal Information is collected and
                shared. You may also have the right to request the categories of
                Personal Information which were disclosed for business purposes,
                and the categories of third parties in the twelve (12) months
                preceding your request for your Personal Information.
              </td>
            </tr>
            <tr>
              <td>Data Portability</td>
              <td>
                You may have the right to receive the Personal Information you
                have previously provided to us.
              </td>
            </tr>
            <tr>
              <td>Erasure</td>
              <td>
                You can request to have your Personal Information deleted from
                our servers and, to the extent we are required to do so, we will
                direct our service providers to do the same. However, please be
                aware that we may not fulfill your request for deletion if we
                (or our service provider(s)) are required to retain your
                Personal Information for one or more of the following categories
                of purposes: (1) to complete a transaction for which the
                Personal Information was collected, provide a good or service
                requested by you, or complete a contract between us and you; (2)
                to ensure our website integrity, security, and functionality;
                (3) to comply with applicable law or a legal obligation, or
                exercise rights under the law (including free speech rights);
                (4) to otherwise use your Personal Information, internally, in a
                lawful manner that is compatible with the context in which you
                provided it.
              </td>
            </tr>
            <tr>
              <td>To Opt Out</td>
              <td>
                You can request to opt out of our sale of your information
                Personal Information.
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <p className="ml-4 mr-4">
        <i>c. How to Exercise Your Rights</i>
        <br />
        <br />
        If you would like to exercise your rights listed above, please send (or
        have your authorized agent send) an email to
        compliance@breachinsured.com. You have a right not to receive
        discriminatory treatment by any business when you exercise your CCPA
        rights.
        <br />
        <br />
        While we take measures to ensure that those responsible for receiving
        and responding to your request are informed of your rights and how to
        help you exercise those rights, when contacting us to exercise your
        rights, we ask you to please adhere to the following guidelines:
      </p>
      <ul className="ml-5 mr-5">
        <li>
          <p>
            Tell Us Which Right You Are Exercising: Specify which right you want
            to exercise and the Personal Information to which your request
            relates (if not to you). If you are acting on behalf of another
            consumer, please clearly indicate this fact and your authority to
            act on such consumer’s behalf;
          </p>
        </li>
        <li>
          <p>
            Help Us Verify Your Identity: Provide us enough information to
            verify your identity. For example, provide us (at a minimum) your
            full name, address, and phone number. Please note that if we cannot
            initially verify your identity, we may request additional
            information to complete the verification process. Any Personal
            Information you disclose to us for purposes of verifying your
            identity will solely be used for the purpose of verification.
          </p>
        </li>
        <li>
          <p>
            Direct Our Response Delivery: Inform us of the delivery mechanism
            with which you prefer to receive our response. You may specify, for
            example, email, or phone. Please note that you do not need to create
            an account with us in order to make a request to exercise your
            rights hereunder.
          </p>
        </li>
      </ul>
      <p className="ml-4 mr-4">
        <i>d. How We Respond to Your Requests</i>
        In all cases, we will respond to your request within 45 days. However,
        where reasonably necessary, we may extend our response time by an
        additional 45 days, provided we send you notice of such extension first.
        We will provide the information to you via your preferred delivery
        mechanism. If the information is provided to you electronically, we will
        provide you the information in a portable format and, to the extent
        technically feasible, in a machine readable, readily useable format that
        allows you to freely transmit this information without hindrance.
        <br />
        <br />
        Please note that we will not charge you for making a request, provided
        that you make no more than two (2) requests per year. If you make three
        (3) or more requests in any given twelve (12) month period, we may
        refuse to respond to such requests, if determined by us to be unfounded
        or excessive (e.g. repetitive in nature), or we may charge a reasonable
        fee taking into account the administrative costs of providing the
        information or communication or taking the action requested. We may also
        deny certain requests, or only partially fulfil them, as permitted or
        required by applicable law. For example, notwithstanding your deletion
        request, we may retain Personal Information that we need to retain for
        legal purposes (for example, tax accounting). If we refuse to act on the
        request, we will provide you notice and the reason for our refusal to
        act.
        <br />
        <br />
        The CCPA gives California Consumers the right to lodge a complaint with
        the California Attorney General’s office. The Attorney General’s office
        may be contacted{' '}
        <a
          href="https://oag.ca.gov/consumers"
          className="text-link"
          target="_blank"
          rel="noopener noreferrer"
        >
          here
        </a>
        .
      </p>
    </div>
    <div>
      <p>
        <span className="border-bottom border-dark">
          <b>10. Storage, Security and Retention of Information</b>
        </span>
      </p>
      <p className="ml-4 mr-4">
        Breach Insurance Solutions, Inc has taken reasonable steps to protect
        the Personal Information users share with us, including, but not limited
        to, setup of processes, equipment and software to avoid unauthorized
        access or disclosure of it. No transmission of Personal Information via
        the Internet can be entirely secure, however, so please always use
        caution when submitting Personal Information. We will retain your
        Personal Information in compliance with the uses described in this
        Policy, as well as to comply with our legal, financial reporting, or
        compliance obligations.
      </p>
    </div>
    <div>
      <p>
        <span className="border-bottom border-dark">
          <b>11. Links to Third Parties and their Sites</b>
        </span>
      </p>
      <p className="ml-4 mr-4">
        Our Platform may include third party content and links to third party
        websites and services that collect Personal Information. We do not
        control these third parties and this Policy does not apply to their
        privacy practices. We are not responsible for the practices of these
        third parties or how they use information you provide to them. Please
        read the third parties’ privacy policies carefully.
      </p>
    </div>
    <div>
      <p>
        <span className="border-bottom border-dark">
          <b>12. Contacting Breach Insurance Solutions, Inc</b>
        </span>
      </p>
      <p className="ml-4 mr-4">
        If you have any questions about this Policy, or the privacy practices of
        Breach Insurance Solutions, Inc, please email us at
        compliance@Breachinsured.com or write to us at:
      </p>
      <address className="ml-5 mr-5">
        Attn: Privacy Officer
        <br />
        Breach Insurance Solutions,
        <br />
        Inc 2133 Lawrenceville-Suwanee Rd <br />
        Suite 12-185 Suwanee,
        <br /> GA 30024
      </address>
    </div>
    <div>
      <p>
        <span className="border-bottom border-dark">
          <b>13. Updates</b>
        </span>
      </p>
      <p className="ml-4 mr-4">
        Please note that this Policy may be amended from time to time. Unless
        otherwise indicated, any changes to this Policy will apply immediately
        upon posting to the Platform so please check our site periodically for
        updates. You can see when this Policy was last updated by reviewing the
        “Last Updated” legend at the top of this page. We will provide notice of
        material changes by updating the Policy here and/or by communicating
        with you in the manner and format in which we typically communicate with
        you in our regular course of business, as required by applicable law
      </p>
    </div>
  </div>
);

const PrivacyPolicy = () => (
  <div className="p-3 p-sm-5 mx-auto" style={{ maxWidth: 1200 }}>
    <PrivacyPolicyBody />
  </div>
);

export default PrivacyPolicy;
