import React from 'react';
import { makeDashboardUri } from '../../utils';

const QUICK_QUOTE_LINK = `${makeDashboardUri(
  window.origin,
)}cryptocurrency-insurance-quick-quote`;

const PromoBar = props => {
  const { value } = props;
  return (
    <div className="promo-bar">
      <a href={QUICK_QUOTE_LINK} className="text-white">
        {value || `No enrollment fee when you use PROMO CODE "BREACH15"`}
      </a>
    </div>
  );
};

export default PromoBar;
