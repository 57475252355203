import React from 'react';
import _ from 'lodash';
import { useQuery } from '@apollo/react-hooks';
import { GET_PROGRAMS } from '../../graphql/Queries';
import CollapseSection from '../partialComponents/CollapseSection';
import Table from '../partialComponents/Table';
import breachMap from '../../assets/images/misc/map.png';

const HACK_COLUMNS = [
  {
    name: 'year',
    displayName: 'Year',
  },
  // {
  //   name: 'numHacks',
  //   displayName: '# of Hacks',
  // },
  {
    name: 'total',
    displayName: (
      <div>
        Crypto Value($USD) <br />
        <small>(valuation as of 12/31/21)</small>
      </div>
    ),
  },
];

const HACK_DATA = [
  { year: 2011, total: `$3.2B`, numHacks: 4 },
  { year: 2012, total: `$6.0B`, numHacks: 4 },
  { year: 2013, total: `$282.5M`, numHacks: 3 },
  { year: 2014, total: `$39.9B`, numHacks: 6 },
  { year: 2015, total: `$1.4B`, numHacks: 5 },
  { year: 2016, total: `$6.3B`, numHacks: 4 },
  { year: 2017, total: `$426M`, numHacks: 4 },
  { year: 2018, total: `$633M`, numHacks: 8 },
  { year: 2019, total: `$696M`, numHacks: 10 },
  { year: 2020, total: `$1.4B`, numHacks: 8 },
  { year: 2021, total: `$455M`, numHacks: 6 },
  {
    year: <span className="text-bold text-primary">Total</span>,
    numHacks: <span className="text-bold  text-primary">62</span>,
    total: <span className="text-bold text-primary">$60.9B</span>,
  },
];

const General = () => {
  const { data } = useQuery(GET_PROGRAMS, {
    variables: { programId: 'crypto-exchange-cover' },
  });
  const states = _.get(data, 'programs[0].regions[0].states', []);

  return (
    <div>
      <CollapseSection title="About Breach">
        Breach Insurance is a global insurance underwriter addressing the
        significant insurance gap in the crypto space by creating regulated
        insurance solutions for the crypto economy. With a Bermuda domiciled
        insurance company, US licensed MGA and TPA operations, Breach is able to
        address the various needs of crypto economy participants.
      </CollapseSection>

      <CollapseSection title="What makes Breach different from alternative solutions to protecting my coins against cyber theft? ">
        Breach Insurance is the only company offering a regulated insurance
        product for retail crypto investors, covering theft of crypto assets
        while in the custody of qualified exchanges.
      </CollapseSection>

      <CollapseSection title="How frequent are exchange hacks?">
        While exchanges continue to strengthen their cybersecurity to combat
        hackers, the unfortunate reality is that these events do happen. We’ve
        compiled that data in the table below.
        <br />
        <br />
        <div className="ml-sm-5 mr-sm-5">
          <Table
            className="text-center"
            columns={HACK_COLUMNS}
            rows={HACK_DATA}
          />
        </div>
      </CollapseSection>

      <CollapseSection title="Where is Breach insurance available?">
        Breach CryptoShield insurance is currently available in{' '}
        {states.join(', ')} and we’ll quickly expand countrywide.
        <img
          src={breachMap}
          alt="Crypto insurance Breach map"
          className="img-fluid mx-auto d-block"
          style={{ maxHeight: 800 }}
        />
      </CollapseSection>

      <CollapseSection title="I have a question that isn’t on this FAQ, where should I go?">
        If you have any questions that do not exist in this FAQ, please email us
        at &nbsp;
        <a className="text-primary" href="mailto:care@breachinsured.com" target="_blank" rel="noopener noreferrer">
          care@breachinsured.com
        </a>{' '}
        or start a chat to speak directly with one of our team members!
      </CollapseSection>
    </div>
  );
}

export default General;
