import React from 'react';
import PropTypes from 'prop-types';

const Table = props => {
  const { rows, columns, className = '' } = props;
  return (
    <table className={`table ${className}`}>
      <thead>
        <tr>
          {columns.map(c => (
            <th key={c.name}>{c.displayName}</th>
          ))}
        </tr>
      </thead>
      <tbody>
        {rows.map((r, idx) => (
          <tr key={`row-${idx}`}>
            {columns.map(c => (
              <td
                className={`text-truncate ${c.className || ''}`}
                key={`row-${idx}-${c.name}`}
              >
                {r[c.name]}
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  );
};

Table.propTypes = {
  rows: PropTypes.array,
  className: PropTypes.string,
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      displayName: PropTypes.any,
    }),
  ),
};

export default Table;
