export const ABOUT_US = `Breach Insurance is a global insurance underwriter addressing the
significant insurance gap in the crypto space by creating regulated
insurance solutions for the crypto economy. With a Bermuda domiciled
insurance company, US licensed MGA and TPA operations, Breach is able to
address the various needs of crypto economy participants.`;

export const DIFFERENT = `Breach Insurance is focused on only offering regulated insurance products
for crypto participants. We believe the regulated route is the best long
term approach for building trust and putting the customer first.`;

export const OTHER_TITLE = `I have an insurance need no one else seems able to help with. What else is Breach able to help with?`;
export const OTHER = `We are constantly listening to our partners and vetting the new and
existing risks they are facing. Our goal is to support the digital asset
space by offering the right products, providing quality education and
promoting healthy risk-management hygiene. If you have a need for a new
product or are facing challenges getting the coverage you’re looking for,
don’t hesitate to reach out.`;

export const DIFFERENT_INSTITUTIONS_TITLE = `What makes Breach different from alternative solutions to protecting my crypto against theft?`;
export const DIFFERENT_INSTITUTIONS = `Breach Insurance is focused on offering a regulated insurance product for
institutional crypto investors, covering theft of crypto assets while in
the custody of qualified custodians.`;

export const DIFFERENT_INDIVIDUALS_TITLE = `What makes Breach different from alternative solutions to protecting my coins against cyber theft?`;
export const DIFFERENT_INDIVIDUALS = `Breach Insurance is the only company offering a regulated insurance
product for retail crypto investors, covering theft of crypto assets while
in the custody of qualified exchanges`;

export const DIFFERENT_SMB_TITLE = `What makes Breach different from other insurance brokerages?`;
export const DIFFERENT_SMB = `Breach Insurance is uniquely positioned to understand the specific needs
of startups and SMBs because we are one! We know what it’s like to be a
small company so we can help educate and guide customers through the
complex insurance buying decisions they have to make.`;

export const WHY_CRYPTO_SHIELD_PRO = `Institutional crypto investors require adequate risk management and risk
transfer options, which we are now able to offer to a variety of
institutions operating in this space. Crypto Shield Pro allows
our insureds to take control of their risk management as the named insured
on their own crypto insurance policy to fulfill obligations to key
stakeholders (e.g. board members, limited partners, investors, clients,
users, etc.).`;

export const CONFIDENTLY_HOW_TITLE = `How does Breach confidently insure assets thefts and hacks?`;
export const CONFIDENTLY_HOW = `We start with having the most robust crypto hack and loss event database
in the insurance and crypto industry, which we have used to accurately
model, simulate, and price for this specific risk by utilizing traditional
actuarial methodology paired with modern statistical techniques.
Furthermore, our underwriting expertise can help insureds control their
insurance costs by recommending risk reduction and mitigation techniques.`;

export const FREQ_HACK_TITLE = `How frequent are exchange hacks?`;
export const FREQ_HACK = `While exchanges continue to strengthen their cybersecurity to combat
hackers, the unfortunate reality is that these events do happen. We’ve
compiled that data in the table below.`;

export const WHERE_ABOUT_US_TITLE = `Where is Breach insurance available?`;
export const WHERE_ABOUT_US = `Through our US MGA and Bermuda Insurance Carrier, Breach is able to meet
clients wherever they are across the globe.`;
