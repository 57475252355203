import React, { useMemo } from 'react';
import _ from 'lodash';
import { useQuery } from '@apollo/react-hooks';
import { GET_EXCHANGES } from '../../graphql/Queries';
import CollapseSection from '../partialComponents/CollapseSection';
import Table from '../partialComponents/Table';

const COINS_COLS = [
  { name: 'fullName', displayName: 'Coin' },
  { name: 'id', displayName: 'Code' },
];

const Exchanges = () => {
  const { data } = useQuery(GET_EXCHANGES);
  const exchangeData = _.get(data, 'exchanges', []);
  const uniqueCoins = useMemo(
    () =>
      _.uniqBy(
        exchangeData.flatMap(item => item.assets),
        item => item.id,
      ),
    [exchangeData],
  );
  const lastEx = exchangeData[exchangeData.length - 1] || { displayName: '' };
  const supportedExchanges = useMemo(
    () =>
      exchangeData
        .map(ex => ex.displayName)
        .join(', ')
        .replace(`, ${lastEx.displayName}`, ` and ${lastEx.displayName}`),
    [exchangeData, lastEx],
  );
  return (
    <div>
      <CollapseSection title="Do I need to connect my wallet?">
        No, there is no need to connect your wallet to purchase Crypto Shield or
        Crypto Shield+ cover.
      </CollapseSection>

      <CollapseSection title="How did you select which exchanges are covered under the policy?">
        We have a very in depth underwriting process to review and approve the
        exchanges that custody coins that we will cover.
      </CollapseSection>

      <CollapseSection title="Which coins can I cover?">
        <div className="m-3">
          <Table rows={uniqueCoins} columns={COINS_COLS} />
        </div>
      </CollapseSection>

      <CollapseSection title="What exchanges are supported?">
        We are currently providing coverage for coins custodied at &nbsp;
        {supportedExchanges}.
      </CollapseSection>

      <CollapseSection title="Will you be expanding to other coins in the future?">
        Don’t see your coin? Email us at care@breachinsured.com to let us know!
      </CollapseSection>

      <CollapseSection title="Will you be expanding to other exchanges in the future?">
        Yes, we are constantly vetting exchanges to make sure they meet our
        underwriting criteria to be eligible for our insurance product.
        <br />
        <br />
        Don’t see your exchange? Email us at care@breachinsured.com to let us
        know!"
      </CollapseSection>

      <CollapseSection title="How did you select which coins would be covered?">
        Our team has a rigorous process to evaluate each coin before the coin is
        approved to be covered under our program.
      </CollapseSection>

      <CollapseSection title="Will you be expanding the types of coins that will be covered?">
        We are always scanning the market and assessing which coins make sense
        for us to cover. You can always find out which coins we cover on our
        website.
      </CollapseSection>
    </div>
  );
};

export default Exchanges;
