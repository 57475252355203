import React from 'react';

import ImageCardWithDescription from '../../components/ImageCardWithDescription';

import license from '../../assets/videos/Landing/Licensed.mp4';

import check from '../../assets/images/misc/check2.png';
import service from '../../assets/videos/Landing/Service.mp4';
import proprietary from '../../assets/videos/Landing/Proprietary.mp4';
import team from '../../assets/videos/Landing/Experienced.mp4';

const BenefitItem = ({ value }) => {
  return (
    <div className="d-flex align-items-center mt-3 mb-3">
      {/* <FontAwesomeIcon icon={faCheckCircle} className="mr-2 text-dark" /> */}
      <img
        src={check}
        alt="check"
        width="50"
        style={{ marginTop: -13, marginBottom: -13 }}
      />

      <div className="text-black f6 font-weight-light">{value}</div>
    </div>
  );
};

const AssetFeature = () => {
  return (
    <div id="asset-feature" className="sub-section">
      <div className="container">
        <div className="row mb-3">
          <div className="text-center col-md-8 mx-auto">
            <h2 className="font-weight-bold">
              Why <span className="underlined-curve">Partner</span>With Us?
            </h2>
          </div>
        </div>

        <ImageCardWithDescription
          title="Licensed &amp; Fully Regulated"
          description={
            <>
              <BenefitItem value="Regulated by the Bermuda Monetary Authority as a class IIGB insurer" />
              <BenefitItem value="We can accept payment and pay claims denominated in crypto" />
              <BenefitItem value="Countrywide licensed as MGA and TPA in the US" />
            </>
          }
          videoFile={license}
        />

        <ImageCardWithDescription
          textRight
          title="Proprietary Products &amp; Tech"
          description={
            <>
              <BenefitItem value="Proprietary platform with quote to payment technology" />
              <BenefitItem value="Submit a claim through an end-to-end digital experience" />
              <BenefitItem value="An intuitive dashboard to easily set up your policy" />
            </>
          }
          videoFile={proprietary}
        />

        <ImageCardWithDescription
          title="Comprehensive Insurance Services"
          description={
            <>
              <BenefitItem value="Embedded Insurance Solutions" />
              <BenefitItem value="Captive and other innovative risk transfer vehicles" />
              <BenefitItem value="Product development and risk management capabilities" />
            </>
          }
          videoFile={service}
        />

        <ImageCardWithDescription
          title="Experienced Team"
          textRight
          description={
            <>
              <BenefitItem value="Over 100 years of deep insurance, investment banking and technology experience" />
              <BenefitItem value="Team consists of executives and leaders from Allied World, Liberty Mutual, IB experience &amp; Uber" />
              <BenefitItem value="Globally represented team - Offices in Bermuda, US, UK &amp; Asia" />
            </>
          }
          videoFile={team}
        />
      </div>
    </div>
  );
};

export default AssetFeature;
