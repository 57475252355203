import React from 'react';
// import logo from '../../assets/images/logo-emblem.png';
import logo from '../../assets/images/logo-emblem-white.png';
import fb from '../../assets/images/socials/facebook.svg';
import linkedin from '../../assets/images/socials/linkedin.svg';
import twitter from '../../assets/images/socials/twitter.svg';
import instagram from '../../assets/images/socials/instagram.svg';

function Footer() {
  return (
    <div className="bg-dark" id="footer">
      <div className="container">
        <div className="">
          <div className="mr-5">
            <a href="/">
              <nobr>
                {/* <img
                  src={logo}
                  width="20"
                  alt="logo"
                  style={{ marginTop: -20, marginRight: 10 }}
                /> */}

                <img
                  src={logo}
                  width="40"
                  alt="logo"
                  style={{ marginTop: -20 }}
                />
              </nobr>
            </a>
            <br />
            <br />

            <div>
              <div className="">
                <h5 className="text-white ">Resources</h5>
                <hr className="hr-white mb-2" style={{ width: 40 }} />
              </div>

              <ul className="list-unstyled d-flex">
                <li className="mr-2">
                  <a href="/terms-conditions" className="text-light">
                    Terms &amp; Conditions <span className="ml-2">.</span>
                  </a>
                </li>
                <li className="ml-1">
                  <a href="/privacy-policy" className="text-light">
                    Privacy Policy<span className="ml-2">.</span>
                  </a>
                </li>
              </ul>
            </div>

            <div className="text-light">
              <div style={{ fontSize: 12 }}>
                Breach Insurance Solutions, Inc (“Breach”) is a licensed
                Property &amp; Casualty (“P&amp;C”) and Surplus Lines insurance
                producer in the states where it transacts insurance. <br />
                <br />
                P&amp;C insurance coverage, on a non-admitted basis, is
                available only to insureds in those states where Breach is
                licensed to transact insurance as a producer and holds valid
                insurer appointments. All coverages are subject to policy terms,
                conditions, and exclusions. CA license # 0N06846
                <br />
                <br />
                Breach Insurance Ltd. All rights reserved. Authorized and
                regulated by the Bermuda Monetary Authority and registered as a
                Class IIGB Insurer, pursuant to Section 4 of the Insurance Act
                1978 (the “Act”). Breach Insurance, Ltd. is not authorized or
                licensed outside of Bermuda and deals only through appropriately
                licensed insurance brokers. To ensure compliance regarding our
                insurance support of companies domiciled in jurisdictions
                outside of Bermuda, any request for coverage must go through the
                appropriate broking channels. The publication and delivery of
                the information contained within our website is not intended as
                a direct solicitation for the purchase of insurance coverage.
              </div>
            </div>
            <br />
            <br />
            <div className="d-flex">
              <a
                href="https://www.facebook.com/BreachInsurance"
                target="_blank"
                rel="noopener noreferrer"
                className="mr-2"
              >
                <img src={fb} width="30" alt="logo" />
              </a>
              <a
                href="https://twitter.com/breachinsured"
                target="_blank"
                rel="noopener noreferrer"
                className="mr-2"
              >
                <img src={twitter} width="30" alt="logo" />
              </a>
              <a
                href="https://www.instagram.com/breachinsurance/"
                target="_blank"
                rel="noopener noreferrer"
                className="mr-2"
              >
                <img src={instagram} width="30" alt="logo" />
              </a>
              <a
                href="https://www.linkedin.com/company/breachinsurance"
                target="_blank"
                rel="noopener noreferrer"
                className="mr-2"
              >
                <img src={linkedin} width="30" alt="logo" />
              </a>
            </div>
            <div className="text-light mb-2 mt-3">
              <small>All rights reserved</small>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Footer;
