import React from 'react';
import { withApollo } from 'react-apollo';
import PropTypes from 'prop-types';
import isEmail from 'isemail';
import Recaptcha from 'react-recaptcha';

import { SEND_MESSAGE } from '../../graphql/Mutations.js';
import ActionButton from '../../components/ActionButton.js';
import toast from '../../toast';

import * as marketingEvents from '../../marketingEvents';
const maxMessageLen = 500;

const checkEmail = email => {
  if (!email) return false;

  if (isEmail.validate(email, { errorLevel: true }) !== 0) {
    return false;
  }
  return true;
};

const initializeJson = () => {
  return {
    email: '',
    name: '',
    phoneNumber: '',
    subject: '',
    msg: '',
    privacyAck: false,
    recapture: '',
    option: 'carrier',
  };
};

class Contactus extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      json: initializeJson(),
      errors: {},
      canSubmit: false,
      buttonState: 'disabled',
      submitErrorText: '',
    };

    this.emailValidatedOnce = false;
    this.handleChange = this.handleChange.bind(this);
    this.handleCheckedChange = this.handleCheckedChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleEmailBlur = this.handleEmailBlur.bind(this);
    this.isFormValid = this.isFormValid.bind(this);
  }

  /**
   * checks the json to see if the data
   * is valid.
   */
  isFormValid(json) {
    const emailValid = checkEmail(json.email);
    if (!emailValid) return false;
    if (json.recapture === '') return false;
    // check privacy acknowledged
    const isValid = !!json.privacyAck;
    return isValid;
  }

  /**
   * Checks that an element has a non-empty `name` property.
   * @param  {Element} element  the element to check
   * @return {Bool}             true if the element is an input, false if not
   */
  isValidElement(element) {
    return !!element.name;
  }

  hasError(name) {
    const error = this.state.errors[name];
    return error;
  }

  handleChange(event) {
    if (this.isValidElement(event.target)) {
      let newValue = event.target.value;
      const name = event.target.name;
      if (newValue.length > event.target.maxLength) {
        newValue = event.target.value.substr(0, event.target.maxLength);
      }

      const that = this;
      this.setState(prevState => {
        const json = {
          ...prevState.json,
          [name]: newValue,
        };
        const isValid = that.isFormValid(json);
        return {
          ...prevState,
          json,
          buttonState: isValid ? '' : 'disabled',
        };
      });

      // if email has already been validated once through blur then
      // validate on change too for better UX.
      if (name === 'email' && this.emailValidatedOnce) {
        this.emailValidation(newValue);
      }
    }
  }

  handleCheckedChange(event) {
    if (this.isValidElement(event.target)) {
      const name = event.target.name;
      event.persist();
      const that = this;
      this.setState(prevState => {
        const json = {
          ...prevState.json,
          [name]: event.target.checked,
        };
        const isValid = that.isFormValid(json);
        return {
          ...prevState,
          json,
          buttonState: isValid ? '' : 'disabled',
        };
      });
    }
  }

  handleRecaptchaChange(val) {
    const that = this;
    this.setState(prevState => {
      const json = {
        ...prevState.json,
        recapture: val,
      };
      const isValid = that.isFormValid(json);
      return {
        ...prevState,
        json,
        buttonState: isValid ? '' : 'disabled',
      };
    });
  }

  handleUserTypeChange = event => {
    const option = event.target.value;
    this.setState(prevState => ({
      json: { ...prevState.json, option },
    }));
  };

  recaptchaExpired() {
    this.handleRecaptchaChange('');
  }

  getTextColor = messageLen => {
    if (maxMessageLen - messageLen === 0) {
      return 'text-danger';
    }
    if (maxMessageLen - messageLen < 20) {
      return 'text-primary';
    }
    return '';
  };

  emailValidation(email) {
    const emailValid = checkEmail(email);
    if (!emailValid) {
      this.setState({ errors: { email: 'Enter a valid email address' } });
    } else {
      this.setState({ errors: { email: null } });
    }
  }

  /**
   * validate the email when the input loses focus
   */
  handleEmailBlur = () => {
    this.emailValidatedOnce = true;
    this.emailValidation(this.state.json.email);
  };

  async handleSubmit(event) {
    event.preventDefault();
    marketingEvents.contactUs();
    this.setState({
      buttonState: 'loading',
      submitErrorText: '',
    });

    try {
      const result = await this.props.client.mutate({
        mutation: SEND_MESSAGE,
        variables: { message: this.state.json },
      });
      // console.log(`sendMessage mutation result: ${JSON.stringify(result)}`);

      if (result.data && result.data.sendMessage.success) {
        this.setState({
          buttonState: 'success',
          submitErrorText: '',
        });
        toast.success('Thanks for your message.');
        setTimeout(() => {
          this.setState({
            buttonState: 'disabled',
            json: initializeJson(),
          });
        }, 1600);
      } else {
        this.setState({
          buttonState: 'error',
          submitErrorText: 'That did not work. Please try again',
        });
        toast.error('That did not work. Please try again');
      }
    } catch (err) {
      this.setState({
        buttonState: 'error',
        submitErrorText: 'That did not work. Please try again',
      });
      toast.error('That did not work. Please try again');
    }
    /*
    const timeout = setTimeout(() => {
      this.setState({
        buttonState: 'error',
        submitErrorText: 'That did not work. Please try again',
      });}, 3000);
    */
  }

  render() {
    const errorMsg = this.hasError('email');

    const messageLen = this.state.json.msg ? this.state.json.msg.length : 0;

    return (
      <div id="contact-us" className="contact-us">
        <div className="container">
          <div className="text-center  mx-auto">
            <h3 className="text-dark font-weight-bold">
              Let&apos;s Get <span className="underlined-curve">in Touch</span>
            </h3>
            <br />
          </div>
          <div className="text-white">
            <div className="contact-us-panel p-4 p-md-5">
              <form>
                <div className="row">
                  <div className="col-lg-6">
                    <div className="form-group">
                      <label className="text-white"> Name</label>
                      <div className="input-with-icon">
                        <input
                          name="name"
                          value={this.state.json.name}
                          onChange={this.handleChange}
                          type="text"
                          maxLength="200"
                          className="form-control"
                        />
                      </div>
                    </div>

                    <div
                      className={`form-group ${errorMsg ? 'has-error' : ''}`}
                    >
                      <label className="text-white"> Your email</label>
                      <div className="input-with-icon">
                        <input
                          name="email"
                          value={this.state.json.email}
                          onChange={this.handleChange}
                          onBlur={this.handleEmailBlur}
                          type="email"
                          required
                          maxLength="250"
                          className="form-control"
                        />
                      </div>
                      <span
                        className={`${
                          errorMsg ? '' : 'd-none'
                        } help-block sub-little-text`}
                      >
                        {errorMsg}
                      </span>
                    </div>

                    <div className="form-group">
                      <label className="text-white"> I am a</label>
                      <select
                        className="form-control text-white"
                        onChange={this.handleUserTypeChange}
                      >
                        <option value="carrier">Carrier</option>
                        <option value="tpa">TPA</option>
                        <option value="broker">Broker</option>
                        <option value="investor">Investor</option>
                        <option value="other">Other</option>
                      </select>
                    </div>
                  </div>

                  <div className="col-lg-6">
                    <label className="text-white"> Your message</label>
                    <div className="mb-3">
                      <textarea
                        name="msg"
                        className="form-control"
                        value={this.state.json.msg}
                        onChange={this.handleChange}
                        rows={5}
                        maxLength={maxMessageLen}
                      />
                      <div
                        className={`text-right ${this.getTextColor(
                          messageLen,
                        )}`}
                      >
                        <small>
                          {maxMessageLen - messageLen} character(s) left
                        </small>
                      </div>
                    </div>
                    <div className="d-md-flex">
                      <div className="">
                        <div className="priv-check">
                          <input
                            name="privacyAck"
                            value={this.state.json.privacyAck ? 'on' : 'off'}
                            onChange={this.handleCheckedChange}
                            className="form-check-input"
                            type="checkbox"
                            id="privacyAck"
                            style={{ marginTop: 8 }}
                          />
                          <label
                            className="form-check-label dasbhoard-description"
                            htmlFor="privacyAck"
                          >
                            By sending this message, you confirm that you have
                            read and agreed to our{' '}
                            <a
                              href="/privacy-policy"
                              className="text-info mouse-pointer"
                            >
                              privacy policy
                            </a>
                            .
                          </label>
                        </div>
                      </div>
                      <div className="ml-3 mt-2 mt-md-0 text-right">
                        <ActionButton
                          className="end"
                          buttonClassName="btn btn-primary"
                          type="submit"
                          controlled={true}
                          state={this.state.buttonState}
                          onClick={this.handleSubmit}
                          errorText={this.state.submitErrorText}
                        >
                          Send
                        </ActionButton>
                      </div>
                    </div>

                    <div className="captcha-row mt-2">
                      <Recaptcha
                        sitekey="6Lem8N0ZAAAAAKsXpFX6q-lARWwWRzY8Iq6X1XZd"
                        render="explicit"
                        verifyCallback={c => {
                          this.handleRecaptchaChange(c);
                        }}
                        expiredCallback={() => {
                          this.recaptchaExpired();
                        }}
                        onloadCallback={() => {
                          console.log('Captcha loaded...');
                        }}
                        theme="light"
                      />
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

Contactus.propTypes = {
  client: PropTypes.object,
};

export default withApollo(Contactus);
