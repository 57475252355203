import React from 'react';

const TermsAndConditionBody = () => (
  <div className="mt-5">
    <br />

    <div className="text-center">
      <h4>BREACH INSURANCE SOLUTIONS, INC TERMS OF USE</h4>
      <i>Last Updated: November 16, 2021</i>
    </div>

    <hr className="solid" />
    <p className="m-0">
      Please carefully read these Terms of Service (this “Agreement”). This
      Agreement between you and Breach Insurance Solutions, Inc as well as our
      affiliates and subsidiaries (collectively “Breach Insurance Solutions,
      Inc”, “us”, “our”, or “we”) governs your use of the websites,
      applications, and electronic communications that link to this Agreement
      (collectively, the “Platform”) and the training materials, content, and
      services available through the Platform (collectively, Platform and all
      related materials, content, and services are referred to herein as the
      “Services”). <br />
      <br />
      By accessing the SERVICES, you agree to be bound by this Agreement WITHOUT
      ANY MODIFICATION. DO NOT ACCESS OR USE THE SERVICES IN ANY WAY IF YOU DO
      NOT AGREE TO THIS AGREEMENT. IF YOU ARE ACCESSING AND USING THE SERVICES
      ON BEHALF OF AN ENTITY, YOU HEREBY REPRESENT AND WARRANT THAT YOU ARE
      AUTHORIZED TO ACT ON BEHALF OF SUCH ENTITY AND TO BIND SUCH ENTITY TO THE
      TERMS OF THIS AGREEMENT.IMPORTANT NOTICE: YOUR USE OF THE SERVICES IS
      SUBJECT TO AN ARBITRATION PROVISION IN SECTION11, REQUIRING ALL CLAIMS TO
      BE RESOLVED VIA INDIVIDUAL BINDING ARBITRATION.
    </p>
    <br />
    <div>
      <p>
        <span className="border-bottom border-dark">
          <b>1. Registration and Eligibility for Services</b>
        </span>
      </p>
      <p className="ml-4 mr-4">
        <b>
          <i>A. Registration.</i>
        </b>
        <br />
        In order to utilize some of the Services’ features, you must register
        and create an account. In order to create an account, you must complete
        the registration process by providing Breach Insurance Solutions, Inc
        with complete and accurate information as prompted by the registration
        form, including contact information, a username, and password. You shall
        protect your password and take full responsibility for your own and
        third-party activities that occur under your account. You agree to
        notify Breach Insurance Solutions, Inc immediately of any unauthorized
        use of your account or any other breach of security.
        <br />
        <br />
        <b>
          <i>B. Refusal of Service.</i>
        </b>
        <br />
        Breach Insurance Solutions, Inc reserves the right, with or without
        notice, to terminate the account of, or refuse service to, any persons
        that violate this Agreement, violate any party’s intellectual property
        rights, misuse the Services, or otherwise engage in inappropriate
        conduct, as determined by Breach Insurance Solutions, Inc in its sole
        discretion.
        <br />
        <br />
        <b>
          <i>C. Information Submission and Age Restriction.</i>
        </b>
        <br />
        By submitting any information, including any User Content as defined
        below, through the Services, you represent and warrant that you are 18
        years of age or older, and, if under the age of majority in your state,
        you are either an emancipated minor, or have obtained the legal consent
        of your parent or legal guardian to enter into this Agreement and use
        the Services. Breach Insurance Solutions, Inc does not intend to use the
        Services to collect any information from children under age 18. Pursuant
        to 47 U.S.C. § 230(d), you are notified that parental control
        protections (such as computer hardware, software, or filtering services)
        are commercially available that may assist you in limiting access to
        material that is harmful to minors. For information on providers of such
        services, contact your information technology professional.
        <br />
        <br />
        <b>
          <i>D. Electronic Communications and Notices.</i>
        </b>
        <br />
        By accepting this Agreement and using the Services, you consent to
        receive electronically all communications or notices sent by Breach
        Insurance Solutions, Inc with regard to the Services or this Agreement
        to any email address you provide to Breach Insurance Solutions, Inc. It
        is your responsibility to update your contact information provided to
        Breach Insurance Solutions, Inc. In order to receive electronic
        communications, you must have a working connection to the internet and
        meet any specifications required by your email service provider. Breach
        Insurance Solutions, Inc may send communications in a non-electronic
        format in Breach Insurance Solutions, Inc’s discretion.
        <br />
        <br />
        You shall provide any notices to us under this Agreement by e-mail or
        mail using the contact information provided below. Unless you tell us
        otherwise, or the law requires otherwise, you agree to receive all
        communications from us by e-mail or through posting notices to your
        account. You are responsible for providing Breach Insurance Solutions,
        Inc with up-to-date contact information, which you may do by updating
        your account information through the Services or by sending a message to
        us via the contact information provided below. You agree that all
        communications that we send to you electronically satisfy any legal
        requirement that a communication be in writing. You may print the
        communications for your records.
      </p>
    </div>
    <div>
      <p>
        <span className="border-bottom border-dark">
          <b>2. Revisions to this Agreement</b>
        </span>
      </p>

      <p className="ml-4 mr-4">
        We may revise and update this Agreement from time to time, and will post
        the updated Agreement to the Services. Unless otherwise stated in the
        amended version of the Agreement, any changes to this Agreement will
        apply immediately upon posting. Other than updating the date at the top
        of the Agreement, we generally will not notify you of any such changes
        by email or other personal contact, but we reserve the right to do so.
        You should revisit this Agreement on a regular basis as revised versions
        will be binding upon you. Your continued use of the Services will
        constitute your agreement to any new provisions within the revised
        Agreement.
      </p>
    </div>
    <div>
      <p>
        <span className="border-bottom border-dark">
          <b>3. Access to and Use of the Services; Proprietary Rights.</b>
        </span>
      </p>

      <p className="ml-4 mr-4">
        <b>
          <i>A. Ownership.</i>
        </b>
        <br />
        All written content, videos, or other materials prepared and posted by
        Breach Insurance Solutions, Inc (not including User Content (defined
        below)), and the Services design, layout, look, appearance, and
        graphics, as well as the trademarks, service marks, and logos contained
        on the Services (collectively, “Breach Insurance Solutions, Inc
        Content”) are owned by or licensed to Breach Insurance Solutions, Inc
        and are subject to copyright, trademark, and other intellectual property
        rights under the United States and foreign laws and international
        conventions. Breach Insurance Solutions, Inc reserves all rights not
        expressly granted in, and to, the Services and the Breach Insurance
        Solutions, Inc Content.
        <br />
        <br />
        <b>
          <i>B. Access and Use of the Services.</i>
        </b>
        <br />
        Subject to, and in accordance with, this Agreement, and contingent upon
        all required payments, Breach Insurance Solutions, Inc agrees to provide
        you with a non-exclusive, non-transferable account enabling you to
        access and use the Services for your needs only (and not for service
        bureau, time sharing, or similar services). Each user account is valid
        for one user only and may not be shared concurrently or otherwise by or
        among multiple users. You also agree to ensure that you exit from your
        account at the end of each session. You should use particular caution
        when accessing your account from a public or shared computer so that
        others are not able to view or record your password or other personal
        information.
        <br />
        <br />
        You are not permitted to use, share, or transfer access to the Services
        in excess of the usage limitations set forth in this Agreement or in any
        manner not expressly authorized by this Agreement or applicable law.
        Your authorization to access and use the Services is automatically
        revoked if you violate any of this Agreement. Breach Insurance
        Solutions, Inc reserves the right to revoke your authorization to access
        or use the Services at any time for any reason. Except as otherwise
        provided in this Agreement, no part of the Services and no Breach
        Insurance Solutions, Inc Content may be copied, reproduced, uploaded,
        posted, publicly displayed, transmitted, or distributed in any way to
        any other computer, server, services, or other medium for publication or
        distribution or for any commercial use without Breach Insurance
        Solutions, Inc’s prior express written consent. Your access to this
        Services is provided on a temporary basis with no guarantee for future
        availability.
        <br />
        <br />
        <b>
          <i>C. Services Availability.</i>
        </b>
        <br />
        We reserve the right to withdraw or amend the Services, and any service
        or material we provide on the Services, in our sole discretion without
        notice. There may be times when the Services are unavailable due to
        technical errors or for maintenance and support activities. We do not
        represent, warrant, or guarantee that the Services will always be
        available or are completely free of human or technological errors. We
        will not be liable if, for any reason, all or any part of the Services
        is unavailable at any time or for any period.
        <br />
        <br />
        You must provide the equipment and internet connections necessary to
        access the Services at your own expense. We do not guarantee that the
        Services will operate with your computer, mobile device, internet
        service plans, or mobile provider service plans or with any particular
        computer or other piece of hardware, software, equipment, or device you
        install on or use with your computer.
        <b>
          <i>D. Changes to the Platform.</i>
        </b>
        <br />
        We may update the content on the Services from time to time, but its
        content is not necessarily complete or up-to-date. Any of the material
        on the Services may be out of date at any given time, and we are under
        no obligation to update such material.
        <br />
        <br />
        We may from time to time develop and provide updates to the Services,
        which may include upgrades, bug fixes, patches and other error
        corrections or new features (collectively, including related
        documentation, “Updates”). Updates may also modify or delete in their
        entirety certain features and functionality. You agree that all Updates
        will be deemed part of the Services and be subject to all terms and
        conditions of this Agreement.
        <br />
        <br />
        You agree that we have no obligation to provide any Updates or to
        continue to provide or enable any particular features or functionality.
        <b>
          <i>E. Securty.</i>
        </b>
        <br />
        You shall be solely responsible for the security, confidentiality, and
        integrity of all information that you receive, transmit through or store
        on the Services. You understand that we cannot and do not guarantee or
        warrant that files available for downloading from the Internet or the
        Services will be free of viruses or other destructive code. You are
        responsible for implementing sufficient procedures and checkpoints to
        satisfy your particular requirements for anti-virus protection and
        accuracy of data input and output, and for maintaining a means external
        to the Services for any reconstruction of any lost data. No data
        transmission over the Internet can be guaranteed to be 100% safe. Thus,
        we cannot warrant that your information will be absolutely secure. You
        shall be solely responsible for any authorized or unauthorized access
        and use of your account by any person. You have the affirmative
        responsibility to monitor and control access to your account
        information.
        <br />
        <br />
        <b>
          <i>E. User Data after Termination. .</i>
        </b>
        <br />
        In the event you terminate this Agreement or your access to the Services
        is otherwise revoked, you will no longer be able to access your user
        data through the Services. However, if requested within thirty (30) days
        after the effective date of such cancellation, termination, or
        revocation, Breach Insurance Solutions, Inc will make available to you
        for download a file of your user data in comma separated value (.csv)
        format. After such thirty-day period, Breach Insurance Solutions, Inc
        shall have no obligation to maintain or provide any user data and may
        thereafter, unless legally prohibited, delete all user data in its
        systems or otherwise in its possession or under its control. The cost to
        provide you the user data pursuant to this section shall be $100.00 or
        such other updated fee to be determined by Breach Insurance Solutions,
        Inc.
      </p>
    </div>
    <div>
      <p>
        <span className="border-bottom border-dark">
          <b>4. User Representations; Restrictions on Use of Services.</b>
        </span>
      </p>
      <p className="ml-4 mr-4">
        You represent and warrant to Breach Insurance Solutions, Inc that:
      </p>
      <ul className="ml-5 mr-5">
        <li>
          <p>You are at least 18 years of age;</p>
        </li>
        <li>
          <p>
            Your use of the Platform and Services does not and will not
            constitute a breach or violation of any other agreement, contract,
            terms of use, or similar policy or understanding to which you are or
            may be subject;
          </p>
        </li>
        <li>
          <p>
            You will not use the Services to violate any statute, law, rule or
            regulation, to violate any agreement between Breach Insurance
            Solutions, Inc and you, or to otherwise violate the legal rights of
            Breach Insurance Solutions, Inc or any third person;
          </p>
        </li>
        <li>
          <p>
            All information provided by you to Breach Insurance Solutions, Inc
            is truthful, accurate and complete;
          </p>
        </li>
        <li>
          <p>
            You are an authorized signatory of the credit or debit card, ACH
            account or other payment method, if any, provided to Breach
            Insurance Solutions, Inc to pay the Fees, Taxes, purchase prices,
            and other charges;
          </p>
        </li>
        <li>
          <p>
            You have provided and will maintain accurate and complete
            registration information with Breach Insurance Solutions, Inc,
            including, without limitation, your legal name, address, and
            telephone number;
          </p>
        </li>
        <li>
          <p>
            You will not access or use the Services in order to gain competitive
            intelligence about Breach Insurance Solutions, Inc, or any product
            or service offered by Breach Insurance Solutions, Inc or to
            otherwise compete with Breach Insurance Solutions, Inc;
          </p>
        </li>
        <li>
          <p>
            You are in fact an authorized representative of the entity on behalf
            of which you purport to act; and
          </p>
        </li>
        <li>
          <p>You comply with all the terms and conditions of this Agreement.</p>
        </li>
      </ul>
      <br />
      <p className="ml-4 mr-4">
        In addition to complying with any other posted terms and conditions
        applicable to your use of the Services, you agree that when using the
        Services, you will not:
      </p>
      <ul className="ml-5 mr-5">
        <li>
          <p>Harm any person(s) in any way;</p>
        </li>
        <li>
          <p>
            Use the Services or any Breach Insurance Solutions, Inc Content in
            any way or for any purpose that would violate, or would have the
            effect of violating, any applicable laws, rules or regulations or
            any rights of any third parties, including without limitation, any
            law or right regarding any copyright, patent, trademark, trade
            secret, or other proprietary or property right, false advertising,
            telemarketing, unfair competition, defamation, invasion of privacy,
            rights of celebrity, or other federal or state law, rule, or
            regulation;
          </p>
        </li>
        <li>
          <p>
            Delete, modify, or attempt to change or alter any of the Breach
            Insurance Solutions, Inc Content or notices on the Services;
          </p>
        </li>
        <li>
          <p>
            Introduce into the Services any virus, rogue program, time bomb,
            drop dead device, back door, Trojan horse, worm or other malicious
            or destructive code, software routines, denial of service attack, or
            equipment components designed to permit unauthorized access to the
            Services, or to otherwise harm other users, Breach Insurance
            Solutions, Inc Content, or any third parties, or perform any such
            actions;
          </p>
        </li>
        <li>
          <p>
            Use the Services in any manner that could disable, overburden,
            damage, or impair the Services or interfere with any other party’s
            use of the Services, including their ability to engage in real time
            activities through the Services;
          </p>
        </li>
        <li>
          <p>
            Use the Services to commit fraud or conduct other unlawful
            activities or to impersonate any person or otherwise falsely state
            or misrepresent your relationship with a person;
          </p>
        </li>
        <li>
          <p>
            Access or attempt to access any other person’s account, information,
            or content without permission;
          </p>
        </li>
        <li>
          <p>
            Copy, modify, create derivative works, reverse engineer, decompile,
            disassemble, or otherwise attempt to learn the source code,
            structure, or ideas upon which the Services is based;
          </p>
        </li>
        <li>
          <p>
            Use any bot, spider, or other automatic or manual device or process
            for the purpose of harvesting or scraping the information contained
            on the Services for any reason;
          </p>
        </li>
        <li>
          <p>Frame or mirror any part of the Services;</p>
        </li>
        <li>
          <p>
            Connect to or access any Breach Insurance Solutions, Inc computer
            system or network without authorization;
          </p>
        </li>
        <li>
          <p>
            Use any of Breach Insurance Solutions, Inc’s trademarks without
            approval, or remove or modify any copyright, trademark, or other
            intellectual property notice that appear on the Platform;
          </p>
        </li>
        <li>
          <p>
            Use the information in the Services to create or sell a similar
            service; or
          </p>
        </li>
        <li>
          <p>
            Attempt to, or permit or encourage any third party, to do any of the
            above.
          </p>
        </li>
      </ul>
      <p className="ml-4 mr-4">
        In order to protect the integrity of the Services, Breach Insurance
        Solutions, Inc reserves the right at any time in its sole discretion to
        block users from certain IP addresses from accessing the Services. You
        may not use any technologies or processes to circumvent any IP blocks or
        other mechanism put in place by Breach Insurance Solutions, Inc to
        limit, restrict, or prevent access to the Services.
      </p>
    </div>
    <div>
      <p>
        <span className="border-bottom border-dark">
          <b>5. User-Generated Content.</b>
        </span>
      </p>
      <p className="ml-4 mr-4">
        The Services may include features that allow you to upload, submit, or
        send content through the Services (e.g., reviews, ratings, photos, or
        comments) (“User Content”). This Section provides the terms and
        conditions governing your use of such features.
      </p>

      <p className="ml-5 mr-5">
        <b>
          <i>A. User Obligations and License; Intellectual Property.</i>
        </b>
        <br />
        You alone own and are solely responsible for your use of the Platform as
        well as any and all User Content you post, upload, or otherwise transmit
        (collectively “Post”) through the Services and once Posted, it cannot
        always be withdrawn. By Posting User Content on the Services, you
        warrant and represent that you have the right to post such information
        and that such information is truthful and accurate.
        <br />
        <br />
        <b>
          <i>B. License to User Content.</i>
        </b>
        <br />
        By submitting User Content to the Services, you grant, and represent and
        warrant that you have the right to grant, Breach Insurance Solutions,
        Inc a worldwide, perpetual, irrevocable, non-exclusive, royalty-free,
        sub-licensable, and transferable license to use, reproduce, distribute,
        create derivative works of, adapt, display, and perform User Content in
        all media now known or hereafter created without attribution for any
        purpose. You hereby waive all moral rights to User Content.
        <br />
        <br />
        <b>
          <i>C. Prohibited Content.</i>
        </b>
        <br />
        You agree that you will not use the Services to send, Post, or publish:
      </p>
      <ul style={{ marginLeft: 40, marginRight: 40 }}>
        <li>
          <p>
            Any content that is illegal, obscene, defamatory, threatening,
            harassing, abusive, slanderous, racially or ethnically offensive,
            hateful, or embarrassing to any other person or entity;
          </p>
        </li>
        <li>
          <p>
            Any review or rating that does not reflect your lawful, honest, and
            good faith opinion or discloses any material conflict of interest or
            relationship that might influence your opinion (e.g., if you are a
            paid endorser of a product that you review);
          </p>
        </li>
        <li>
          <p>
            Any message, data, code, or software that would violate our, or any
            third party, proprietary, or intellectual property rights, including
            unauthorized copyright text, images, programs, trade secrets, or
            other confidential or proprietary information, or use trademarks or
            service marks in an infringing fashion;
          </p>
        </li>
        <li>
          <p>
            Any personal information of a third party, or images that include a
            third party or depict a third party’s likeness, without the third
            party’s consent;
          </p>
        </li>
        <li>
          <p>
            Any advertisements or solicitations of business, chain letters,
            pyramid schemes, or bulk e-mail lists or upload;
          </p>
        </li>
        <li>
          <p>
            Any materials that violate, could cause us or a third party to
            violate, or encourage us or a third party to violate any applicable
            law, statute, ordinance, or regulation; or
          </p>
        </li>
        <li>
          <p>
            Any content or communications intended to impersonate someone else.
          </p>
        </li>
      </ul>
      <p className="ml-5 mr-5">
        <b>
          <i>D. Your Responsibility for User Content.</i>
        </b>
        <br />
        Your User Content is your sole responsibility and you assume all risks
        associated with your Posted User Content. Under no circumstances will we
        be liable in any way for User Content or for any loss or damage of any
        kind incurred as a result of the use of any of User Content. Breach
        Insurance Solutions, Inc further reserves the right to monitor, delete
        or modify any User Content that it deems offensive, inappropriate,
        advertising, illegal, off-topic or which otherwise violates this
        Agreement.
        <br />
        <br />
        <b>
          <i>E. Communications Decency Act.</i>
        </b>
        <br />
        As provided in 47 U.S.C. § 230(c)(1), Breach Insurance Solutions, Inc is
        only a distributer, and not the publisher or speaker, of any User
        Content. As such, Breach Insurance Solutions, Inc cannot be held liable
        for making available any User Content which may be false or inaccurate.
        Any information or opinions contained in the User Content made available
        through the services are those of their respective authors alone. Breach
        Insurance Solutions, Inc does not guarantee the accuracy, completeness,
        or truthfulness of any User Content. Under no circumstances will Breach
        Insurance Solutions, Inc be responsible for any loss or damage resulting
        from any person’s reliance on any User Content.
        <br />
        <br />
        <b>
          <i>F. Information Storage and Access.</i>
        </b>
        <br />
        Breach Insurance Solutions, Inc reserves the right, in its sole
        discretion, to determine whether and how long to store User Content and
        user data in accordance with our Privacy Policy. Breach Insurance
        Solutions, Inc will not be responsible for any liability related to the
        deletion or removal or any data or content maintained on the Services or
        Breach Insurance Solutions, Inc’s servers.
        <br />
        <br />
        <b>
          <i>G. Reservation of Rights.</i>
        </b>
        <br />
        Breach Insurance Solutions, Inc reserves the right to remove or not
        remove any User Content from the Services for any reason or no reason at
        all, in Breach Insurance Solutions, Inc’s sole discretion. This
        reservation includes the exclusive right to decide whether to publish,
        withdraw, postpone, or alter any User Content. Breach Insurance
        Solutions, Inc reserves the right, but does not undertake any
        affirmative obligation, to screen, monitor, or filter User Content.
        <br />
        <br />
        <b>
          <i>H.Your Suggestions.</i>
        </b>
        <br />
        We welcome your comments regarding the Services and Breach Insurance
        Solutions, Inc Content, and our services. In addition to the license you
        grant to us, above, for User Content, if you elect to provide or make
        available suggestions, comments, ideas, improvements, or other
        information or materials (collectively, “Suggestions”) to us in
        connection with or related to the Services, Breach Insurance Solutions,
        Inc Content or our services (including any related technology), whether
        you send such Suggestions to us through the Services or through a
        separate communication channel, you grant us a worldwide, perpetual,
        irrevocable, non-exclusive, royalty-free, sub-licensable, and
        transferable license under any and all rights in and to the Suggestions
        to use, reproduce, distribute, create derivative works of, adapt,
        display, perform, and otherwise exploit, and to make, have made, sell,
        offer to sell, and import any products and services incorporating or
        based on, Suggestions in any manner. Please do not send us such
        information or materials if you do not wish to grant us the rights set
        forth in this Section.
      </p>
    </div>
    <div>
      <p>
        <span className="border-bottom border-dark">
          <b>6. User Privacy.</b>
        </span>
        <br />
      </p>
      <p className="ml-4 mr-4">
        The Services are governed by the Breach Insurance Solutions, Inc Privacy
        Policy which is located here . Please read the Privacy Policy before
        accessing the Services or providing any personal information through the
        Services.
      </p>
    </div>
    <div>
      <p>
        <span className="border-bottom border-dark">
          <b>7. Third Party Websites and Services.</b>
        </span>
      </p>
      <p className="ml-4 mr-4">
        The Services may use or provide access to one or more third parties to
        process payments, process account or user registrations, or provide
        other services. Your interactions with any of these third parties is
        controlled by the terms and conditions imposed by those third parties.
        Any disputes arising regarding a third party’s services must be resolved
        directly between user and the third party. Breach Insurance Solutions,
        Inc disclaims all warranties or representations regarding any
        third-party services. Breach Insurance Solutions, Inc, in its sole
        discretion, and without notice to you or any user, may subcontract any
        services related to the Services to be performed by a third party.
      </p>
    </div>
    <div>
      <p>
        <span className="border-bottom border-dark">
          <b>8. Disclaimer of Warranties.</b>
        </span>
      </p>
      <p className="ml-4 mr-4">
        EXCEPT AS WE OTHERWISE EXPRESSLY PROVIDE IN THIS AGREEMENT, THE SERVICES
        AND ALL RELATED SERVICES OF BREACH INSURANCE SOLUTIONS, INC ARE PROVIDED
        “AS IS”. USE OF THE SERVICES IS AT YOUR SOLE RISK. WE, OUR THIRD-PARTY
        LICENSORS, AND BUSINESS PARTNERS DO NOT WARRANT OR MAKE ANY PROMISES
        REGARDING THE CORRECTNESS, USEFULNESS,ACCURACY, AVAILABILITY, OR
        RELIABILITY OF: (i) YOUR USE OR THE RESULTS OF YOUR USE OF THE SERVICES;
        (ii) ANY ADVICE YOU GLEAN FROM THE SERVICES WHETHER PROVIDED BY US OR A
        THIRD PARTY; OR (iii) ANY OTHER CONTENT AVAILABLE THROUGH THE SERVICES.
        WE DO NOT PROMISE THAT THE SERVICES WILL BE UNINTERRUPTED OR ERROR FREE,
        OR THAT ANY DEFECTS WILL BE CORRECTED. WE GIVE NO WARRANTY OF ANY KIND,
        INCLUDING ANY WARRANTY OF TITLE, MERCHANTABILITY, NON-INFRINGEMENT, OR
        FITNESS FOR A PARTICULAR PURPOSE. WE, OUR THIRD-PARTY LICENSORS, AND
        BUSINESS PARTNERS WILL HAVE NO RESPONSIBILITY FOR THE TIMELINESS,
        DELETION, MISDELIVERY, OR FAILURE TO STORE ANY COMMUNICATION, OR
        CONTENT. WE DO NOT MAKE ANY REPRESENTATION OR WARRANTY CONCERNING
        ERRORS, OMISSIONS, DELAYS, OR DEFECTS IN THE SERVICES OR ANY INFORMATION
        SUPPLIED TO YOU VIA THE SERVICES, OR THAT FILES AVAILABLE THROUGH
        SERVICES ARE FREE OF VIRUSES, WORMS, TROJAN HORSES, OR OTHER CODE THAT
        INCLUDE OR MANIFEST CONTAMINATING OR DESTRUCTIVE CHARACTERISTICS. BREACH
        INSURANCE SOLUTIONS, INC IS NOT A BACKUP SERVICE FOR STORING USER DATA,
        AND BREACH INSURANCE SOLUTIONS, INC SHALL HAVE NO LIABILITY REGARDING
        ANY LOSS OF USER DATA. USERS ARE SOLELY RESPONSIBLE FOR CREATING BACKUPS
        OF ANY USER DATA UPLOADED USING THE ONLINE SERVICES. <br />
        <br />
        WHILE BREACH INSURANCE SOLUTIONS, INC USES COMMERCIALLY REASONABLE
        EFFORTS TO UPDATE THE SERVICES IN ACCORDANCE WITH CONTINUALLY CHANGING
        LAWS, CODES, STANDARDS, REQUIREMENTS AND REGULATIONS (COLLECTIVELY,
        “LAWS”), INFORMATION AND FORMS, USER MUST ALWAYS EXAMINE THE MOST
        CURRENT LAWS, INFORMATION AND FORMS TO ENSURE THAT USER IS IN FULL
        COMPLIANCE WITH ANY AND ALL APPLICABLE LAWS. WITHOUT LIMITING THE
        GENERALITY OF THE FOREGOING DISCLAIMERS, BREACH INSURANCE SOLUTIONS, INC
        DOES NOT WARRANT THAT USE OF THE SERVICES WILL RESULT IN USER’S
        COMPLIANCE WITH ANY APPLICABLE LAWS, AND USER UNDERSTANDS AND
        ACKNOWLEDGES THAT IT IS SOLELY RESPONSIBLE FOR ENSURING ITS COMPLIANCE
        WITH ANY AND ALL APPLICABLE LAWS. BY PROVIDING THE SERVICES, BREACH
        INSURANCE SOLUTIONS, INC IS NOT PROVIDING USER WITH LEGAL ADVICE. <br />
        <br />
        THE FOREGOING EXCLUSIONS AND DISCLAIMERS ARE AN ESSENTIAL PART OF THIS
        AGREEMENT. SOME STATES DO NOT ALLOW EXCLUSION OF AN IMPLIED WARRANTY, SO
        THESE DISCLAIMERS MAY NOT APPLY TO YOU. ALL PRODUCTS YOU PURCHASE
        THROUGH THE SERVICES ARE SOLD BY THE SELLER AND NOT BY BREACH INSURANCE
        SOLUTIONS, INC.
      </p>
    </div>
    <div>
      <p>
        <span className="border-bottom border-dark">
          <b>9. Limitation of Liability .</b>
        </span>
      </p>
      <p className="ml-4 mr-4">
        <b>
          <i>A. Services and Related Conduct.</i>
        </b>
        <br />
        NEITHER BREACH INSURANCE SOLUTIONS, INC, NOR ANY OF ITS SUBSIDIARIES,
        AFFILIATES, OR THEIR RESPECTIVE OFFICERS, DIRECTORS, AGENTS,
        CONTRACTORS, BUSINESS PARTNERS, LICENSORS, EMPLOYEES, ASSIGNEES, AND
        SUCCESSORS-IN-INTEREST (COLLECTIVELY, THE “BREACH INSURANCE SOLUTIONS,
        INC PARTIES”), WILL BE LIABLE TO YOU OR ANY THIRD PARTY FOR ANY CLAIMS,
        DEMANDS, OR CAUSES OF ACTION, DIRECT OR INDIRECT, SPECIAL, INCIDENTAL,
        CONSEQUENTIAL, EXEMPLARY, OR PUNITIVE DAMAGES OR LOST PROFITS OR LOSS OF
        GOODWILL OR BUSINESS REPUTATION OR LOSS OF DATA OR COST OF PROCUREMENT
        OF SUBSTITUTE PRODUCTS OR SERVICES OR OTHER INTANGIBLE LOSS, HOWEVER
        CAUSED AND UNDER ANY THEORY OF LIABILITY, RELATING TO THIS AGREEMENT,
        YOUR USE OF THE SERVICES, OR ANY INFORMATION YOU OBTAIN ON IT, OR ANY
        OTHER INTERACTION WITH THE SERVICES, AND YOU VOLUNTARILY AND
        UNEQUIVOCALLY WAIVE ANY LIABILITY OF THE BREACH INSURANCE SOLUTIONS, INC
        PARTIES. FURTHER, BREACH INSURANCE SOLUTIONS, INC SHALL NOT BE LIABLE
        FOR ANY LOSS OR DAMAGE TO YOU AS A RESULT OF: (A) THE USE OF OR INABILTY
        TO USE THE SERVICES; (B) PERSONAL INJURY OR PROPERY DAMAGE OF ANY KIND
        WHATSOEVER CAUSED BY YOUR ACCESS TO, USE, OR MISUSE OF THE SERVICES; (C)
        ANY RELIANCE, WHETHER DIRECTLY OR INDIRECTLY, PLACED BY YOU ON THE
        COMPLETENESS, ACCURACY OR EXISTENCE OF THE SERVICES, INCLUDING BUT NOT
        LIMITED TO, AS A RESULT OF ANY RELATIONSHIP OR TRANSACTION BETWEEN YOU
        AND ANY FINANCIAL INSTITUTION, LENDER, LICENSOR, DISTRIBUTOR,
        FULFILLMENT CENTER, SUPPLIER, SPONSOR OR ANY OTHER THIRD PARTY FOR WHOM
        YOU ARE ASKING PRODUCTS OR SERVICES; (D) ANY CHANGES WHICH BREACH
        INSURANCE SOLUTIONS, INC MAY MAKE TO THE SERVICES, OR FOR ANY PERMANENT
        OR TEMPORARY CESSATION IN THE PROVISION OF THE SERVICES (OR ANY FEATURES
        WITHIN THE SERVICES); (E) THE DELETION OF, CORRUPTION OF, OR FAILURE TO
        CURE, ANY CONTENT, DATA, INFORMATION AND/OR OTHER COMMUNICATIONS OR DATA
        MAINTAINED OR TRANSMITTED BY OR THROUGH YOUR USE OF THE SERVICES; OR (F)
        YOUR FAILURE TO PROVIDE BREACH INSURANCE SOLUTIONS, INC WITH ACCURATE
        INFORMATION. BREACH INSURANCE SOLUTIONS, INC IS NOT AN INSURER WITH
        REGARD TO PERFORMANCE OF THE SERVICES. THE DISCLAIMER OF WARRANTIES AND
        THE LIMITATION OF LIABILITY AND REMEDY ARE A REFLECTION OF THE RISKS
        ASSUMED BY THE PARTIES IN ORDER FOR USER TO OBTAIN THE RIGHTS TO USE THE
        SERVICES AT THE SPECIFIED PRICE, IF ANY. USER AGREES TO ASSUME THE RISK
        FOR: (i) ALL LIABILITIES DISCLAIMED BY BREACH INSURANCE SOLUTIONS, INC
        CONTAINED HEREIN; AND (ii) ALL ALLEGED DAMAGES IN EXCESS OF THE AMOUNT,
        IF ANY, OF THE LIMITED REMEDY PROVIDED HEREUNDER. YOUR SOLE AND
        EXCLUSIVE REMEDY FOR DISSATISFACTION WITH THE SERVICES WILL BE TO STOP
        USING THE SERVICES.
        <br />
        <br />
        <b>
          <i>
            B. Breach Insurance Solutions, Inc’s Maximum Liability for Any
            Claim.
          </i>
        </b>
        <br />
        IN ANY EVENT, THE MAXIMUM TOTAL LIABILITY OF THE BREACH INSURANCE
        SOLUTIONS, INC PARTIES, FOR ANY CLAIM WHATSOEVER RELATING IN ANY WAY TO
        THIS AGREEMENT OR YOUR USE OF THE SERVICESOR PURCHASE OF A PRODUCT
        THROUGH THE SERVICES OR YOUR USE OF ANY SUCH PRODUCT, INCLUDING CLAIMS
        FOR BREACH OF CONTRACT, TORT (INCLUDING, NEGLIGENCE OR STRICT LIABILITY)
        OR OTHERWISE, AND YOUR SOLE REMEDY, SHALL BE AN AWARD FOR DIRECT
        PROVABLE DAMAGES NOT TO EXCEED ONE HUNDRED U.S. DOLLARS ($100.00 USD).
        <br />
        <br />
        <b>
          <i>C. State Law Waiver.</i>
        </b>
        <br />
        In entering into this release you expressly waive any protections
        (whether statutory or otherwise), including Section 1542 of the
        California Civil Code if applicable (and any other comparable statute),
        that would otherwise limit the coverage of this release to include only
        those claims which you may know or suspect to exist in your favor at the
        time of agreeing to this release. Section 1542 of the California Civil
        Code reads as follows: “A general release does not extend to claims that
        the creditor or releasing party does not know or suspect to exist in his
        or her favor at the time of executing the release and that, if known by
        him or her, would have materially affected his or her settlement with
        the debtor or released party.”
      </p>
    </div>
    <div>
      <p>
        <span className="border-bottom border-dark">
          <b>10. Indemnification.</b>
        </span>
      </p>
      <p className="ml-4 mr-4">
        To the fullest extent permitted by applicable law, you agree to hold
        harmless, indemnify, and defend Breach Insurance Solutions, Inc from and
        against any and all claims (including liabilities, fines, damages,
        losses, costs, expenses, and reasonable attorneys’ fees) arising out of
        or relating to (i) your use of the Services; (ii) your breach of any
        term or condition of this Agreement, (iii) your acts or omissions during
        use of the Services, or (iv) your unauthorized acts or omissions during
        use of the Services.
        <br />
        <br />
        You will have the right to defend and compromise such claim at your
        expense for the benefit of the Breach Insurance Solutions, Inc Parties;
        provided, however, you will not have the right to obligate the Breach
        Insurance Solutions, Inc Parties in any respect in connection with any
        such settlement without the written consent of the indemnified party;
        provided, further, Breach Insurance Solutions, Inc will have the right
        to participate in the defense of such claim at its expense using counsel
        of its choice. Notwithstanding the foregoing, if you fail to assume your
        obligation to defend or if Breach Insurance Solutions, Inc elects to
        defend such claims itself, the Breach Insurance Solutions, Inc Parties
        may do so to protect their interests and you will reimburse all costs
        incurred by the Breach Insurance Solutions, Inc Parties in connection
        with such defense.
      </p>
    </div>
    <div>
      <p>
        <span className="border-bottom border-dark">
          <b>11. Agreement to Arbitrate and Prohibition on Class Actions .</b>
        </span>
      </p>
      <p className="ml-4 mr-4">
        <b>
          <i>A. Choice of Law.</i>
        </b>
        <br />
        The validity, construction, and effect of this Agreement will be
        governed by the laws of the state of Delaware, without giving effect to
        that state’s conflict of laws rules. Any legal suit, action or
        proceeding arising out of, or related to, the Agreement or the Services,
        shall be instituted exclusively in the federal courts of the United
        States or the courts of the State of Delaware, in each case located in
        Delaware, although we retain the right to bring any suit, action, or
        proceeding against you for breach of this Agreement in your country or
        county of residence, or any other relevant country or county. You waive
        any and all objections to the exercise of jurisdiction over you by such
        courts and to venue in such courts.
        <br />
        <br />
        <b>
          <i>B. Arbitration Procedure.</i>
        </b>
        <br />
        If you have any issue or dispute with Breach Insurance Solutions, Inc
        you agree to first contact us at compliance@breachinsured.com and
        attempt to resolve the dispute with us informally. All disputes arising
        out of, or relating to, this Agreement (including formation,
        performance, breach, enforceability, and validity of this Agreement),
        our operation of the Services, or a purchase made through the Services
        shall be resolved by final and binding arbitration pursuant to the
        Commercial Arbitration Rules of the American Arbitration Association.
        The arbitration will be held in the state of Delaware or another
        location if Breach Insurance Solutions, Inc consents to such other
        location, which consent may be withheld in the sole discretion of Breach
        Insurance Solutions, Inc. The arbitrator, and not any federal, state, or
        local court or agency, shall have exclusive authority to resolve any
        dispute relating to the interpretation, applicability, enforceability,
        or formation of this Agreement, including any claim that all or any part
        of this Agreement is void or voidable. The award rendered by the
        arbitrator shall include costs of arbitration, reasonable
        attorneys&apos; fees, and reasonable costs for expert and other
        witnesses, and any judgment on the award rendered by the arbitrator may
        be entered in any court of competent jurisdiction.
        <br />
        <br />
        <b>
          <i>C. Waiver of Class Actions; Jury Trials.</i>
        </b>
        <br />
        We each agree that any dispute resolution proceedings will be conducted
        only on an individual basis and not in a class, consolidated, or
        representative action. Class actions and class arbitrations are
        prohibited. If for any reason a claim proceeds in court rather than in
        arbitration, we each waive any right to a jury
        <br />
        <br />
        <b>
          <i>D. Limitation on Claims.</i>
        </b>
        <br />
        No action arising under this Agreement may be brought by any user more
        than one (1) year after the cause of action has accrued.
        <br />
        <br />
        <b>
          <i>E.Injunctive Relief.</i>
        </b>
        <br />
        Without prejudice to the agreement to resolve disputes in binding
        arbitration set forth in the previous paragraph, either party to this
        Agreement may obtain preliminary injunctive relief in a court of
        competent jurisdiction, for the purpose of enforcing any of the terms of
        this Agreement pending a final determination in arbitration or permanent
        relief for the purpose of enforcing arbitral awards.
      </p>
    </div>
    <div>
      <p>
        <span className="border-bottom border-dark">
          <b>12. Hyperlinks.</b>
        </span>
      </p>
      <p className="ml-4 mr-4">
        A link from the Services to a non-Breach Insurance Solutions, Inc
        website does not mean that Breach Insurance Solutions, Inc endorses or
        accepts any responsibility for the content, functioning, policies, or
        use of such services, and you enter any such website at your own risk.
        It is your responsibility to take precautions to ensure that whatever
        website or other online materials that you select for use are free of
        viruses and other items of a destructive nature. Additionally, we
        suggest you review the linked site’s terms of service and privacy
        policy, and if you do not agree to be bound by the terms of that site,
        terminate your visit to that site. We are not responsible for the
        privacy policies and practices of the sites operated by our business
        partners or other third parties. Breach Insurance Solutions, Inc
        expressly disclaims any liability related to such sites. Breach
        Insurance Solutions, Inc also prohibits unauthorized hypertext links to
        the Services or the framing of any content available through the
        Services. Breach Insurance Solutions, Inc reserves the right to disable
        any unauthorized links or frames.
      </p>
    </div>
    <div>
      <p>
        <span className="border-bottom border-dark">
          <b>13. Special Admonitions for International Use.</b>
        </span>
      </p>
      <p className="ml-4 mr-4">
        We make no claims that the Services are accessible or appropriate
        outside of the United States. Access to the Platform may not be legal by
        certain persons or in certain countries. If you access the Platform from
        outside the United States, you do so on your own initiative. Recognizing
        the global nature of the internet, you agree to comply with all local
        rules regarding online conduct and acceptable content. Specifically, you
        agree to comply with all applicable laws regarding the transmission of
        technical data exported from the United States or the country in which
        you reside and to comply with any other local laws affecting the
        transmission or posting of content or affecting the privacy of persons.
        <br />
        <br />
        The U.S. Department of the Treasury, through the Office of Foreign
        Assets Control (&quote;OFAC&quote;), prohibits U.S. companies from
        engaging in all or certain commercial activities with certain sanctioned
        countries (each a &quote;Sanctioned Country&quote;) and certain
        individuals, organizations or entities, including without limitation,
        certain &quote;Specially Designated Nationals&quote; (&quote;SDN&quote;)
        listed by OFAC. If you are located in a Sanctioned Country or are listed
        as an SDN, you are prohibited from registering or signing up with,
        subscribing to, or using the Services. If Breach Insurance Solutions,
        Inc determines that the Services are being used by prohibited persons,
        Breach Insurance Solutions, Inc will terminate any impacted accounts. We
        reserves the right to also provide notification of any such usage to the
        US DOT/OFAC.
      </p>
    </div>
    <div>
      <p>
        <span className="border-bottom border-dark">
          <b>14. Special Admonitions for International Use.</b>
        </span>
      </p>
      <p className="ml-4 mr-4">
        We have the right to fully cooperate with any law enforcement
        authorities, regulatory agencies, or court order requesting or directing
        us to disclose the identity or other information of anyone sharing
        information with us through the Platform. YOU WAIVE AND HOLD HARMLESS
        THE COMPANY AND ITS AFFILIATES, LICENSEES AND SERVICE PROVIDERS FROM ANY
        CLAIMS RESULTING FROM ANY ACTION TAKEN BY THE COMPANY AND/OR ANY OF THE
        FOREGOING PARTIES DURING OR AS A RESULT OF ITS INVESTIGATIONS AND FROM
        ANY ACTIONS TAKEN AS A CONSEQUENCE OF INVESTIGATIONS BY EITHER THE
        COMPANY OR SUCH PARTIES OR LAW ENFORCEMENT AUTHORITIES.
      </p>
    </div>
    <div>
      <p>
        <span className="border-bottom border-dark">
          <b>15. Miscellaneous Terms</b>
        </span>
      </p>
      <p className="ml-4 mr-4">
        <b>
          <i>A. Term and Termination.</i>
        </b>
        <br />
        You may terminate this Agreement at any time by closing your account,
        discontinuing your use of the Services, and providing Breach Insurance
        Solutions, Inc with a notice of termination; however, you will not be
        entitled to any refund of any prepaid amounts. Please review this
        Agreement and our Privacy Policy for information about what we do with
        your account when terminated. We may terminate your account, suspend
        your ability to use certain portions of the Services and/or ban you
        altogether from the Services for any or no reason, and without notice or
        liability of any kind. Any such action could prevent you from accessing
        your account, the Services, your User Content, other content on the
        Services, or any other related information. In the event of any
        termination of this Agreement, any provisions which by their nature
        should continue following termination shall so continue.
        <br />
        <br />
        <b>
          <i>B. Complete Agreement.</i>
        </b>
        <br />
        This Agreement, along with the Privacy Policy, constitutes the entire
        agreement between you and Breach Insurance Solutions, Inc relating to
        your use of, and access to, the Services and supersedes any prior or
        contemporaneous agreements or representations. This Agreement may not be
        amended except as set forth herein. Any user of the Services may be
        subject to additional terms and services that may apply through the use
        of affiliated services or third party sites.
        <br />
        <br />
        <b>
          <i>C. Independent Contractors.</i>
        </b>
        <br />
        The parties and their respective personnel, are and shall be independent
        contractors and neither party by virtue of this Agreement shall have any
        right, power or authority to act or create any obligation, express or
        implied, on behalf of the other party.
        <br />
        <br />
        <b>
          <i>D. Force Majeure.</i>
        </b>
        <br />
        Breach Insurance Solutions, Inc shall not be liable for any failure to
        perform any services or other obligation related to this Agreement or
        the Services to the extent that performance of its obligations are
        delayed or prevented by reason of any act of God, fire, natural
        disaster, accident, riots, acts of government, shortage of materials or
        supplies, or any other cause beyond the reasonable control of Breach
        Insurance Solutions, Inc.
        <br />
        <br />
        <b>
          <i>E. Severability</i>
        </b>
        <br />
        If any portion of this Agreement is ruled invalid or otherwise
        unenforceable, it shall be deemed amended in order to achieve as closely
        as possible the same effect as originally drafted. Any invalid or
        unenforceable portion should be construed as narrowly as possible in
        order to give effect to as much of this Agreement as possible.
        <br />
        <br />
        <b>
          <i>F. No Waivers. </i>
        </b>
        <br />
        Our failure to enforce or exercise any provision of this Agreement or
        related right will not constitute a waiver of that right or provision.
        This Agreement shall not be modified by any course of performance or
        course of dealing.
        <br />
        <br />
        <b>
          <i>G. No Assignments and Transfers.</i>
        </b>
        <br />
        No rights or obligations under this Agreement may be assigned or
        transferred by you, either voluntarily or by operation of law, without
        our express prior written consent and in our sole discretion.
        <br />
        <br />
        <b>
          <i>H. No Third Party Beneficiaries.</i>
        </b>
        <br />
        Subject to Sections 9 and 10, nothing in this Agreement will confer upon
        any person or entity, other than the parties, any rights, remedies,
        obligations, or liabilities whatsoever.
      </p>
    </div>
    <div>
      <p>
        <span className="border-bottom border-dark">
          <b>16. Contact Us</b>
        </span>
      </p>
      <p className="ml-4 mr-4">
        If you have any questions or need to contact us for any reason relating
        to this Agreement, please e-mail: compliance@breachinsured.com
        <br />
        You may also send us mail at the following address:
      </p>
      <address className="ml-5 mr-5">
        2133 Lawrenceville-Suwanee Rd <br /> Suite 12-185 <br /> Suwanee, GA
        30024
      </address>
    </div>
  </div>
);

const TermsAndCondition = () => (
  <div className="p-3 p-sm-5 mx-auto" style={{ maxWidth: 1200 }}>
    <TermsAndConditionBody />
  </div>
);

export default TermsAndCondition;
