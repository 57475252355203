import React from 'react';

import { useHistory } from 'react-router-dom';
import ProductCard from './ProductCard';
import individualsIcon from '../../../assets/images/product-summary/individuals2.png';
import institutionsIcon from '../../../assets/images/product-summary/institutions2.png';
import smeIcon from '../../../assets/images/product-summary/sme2.png';
import logoCyptoProShield from '../../../assets/images/product-summary/logo-crypto-shield-pro.svg';
import logoCyptoCorporateShield from '../../../assets/images/product-summary/logo-crypto-corporate.svg';

const ProductCards = () => {
  const history = useHistory();

  return (
    <div id="product-summary" className="sub-section">
      <div className="container">
        <div className="row">
          <div className="text-center col-md-8 mx-auto mt-5">
            <h2 className="font-weight-bold">
              What Are You <span className="underlined-curve">Looking</span>For?
            </h2>
          </div>
        </div>
        <br />
        <br />

        <div className="d-lg-flex  justify-content-around">
          <ProductCard
            className="w-100"
            titleClass="underlined-straight"
            icon={individualsIcon}
            titleIcon={logoCyptoProShield}
            onClick={() => history.push('/cryptoshieldproplatforms')}
            actionTitle="Learn More"
            iconWidth={32}
            title="Platforms"
            description={
              <div className="text-left">
                Purpose-built for crypto partners looking to embed insurance
                into their business models. Tailored coverage for your
                platform’s specific needs
              </div>
            }
          />
          <ProductCard
            title="Institutions"
            className="m-0 ml-lg-4 w-100"
            titleClass="underlined-straight"
            icon={institutionsIcon}
            titleIcon={logoCyptoProShield}
            onClick={() => history.push('/cryptoshieldpro')}
            actionTitle={
              <span>
                About Crypto Shield<sup>Pro</sup>
              </span>
            }
            description={
              <div className="text-left">
                Insurance for the loss and theft of crypto has traditionally
                been available to only established crypto custodians or
                exchanges. Institutional crypto investors are now able to take
                control of their risk management as the named insured on their
                own crypto insurance policy to fulfill obligations to key
                stakeholders.
              </div>
            }
          />
          <ProductCard
            title="SMBs"
            className="m-0 ml-lg-4 w-100"
            titleClass="underlined-straight"
            icon={smeIcon}
            iconWidth={35}
            titleIcon={logoCyptoCorporateShield}
            onClick={() => history.push('/smb')}
            actionTitle="See our products"
            description={
              <div className="text-left">
                We understand startup and small business risks because we’ve
                been there. Whether it’s Cyber, GL, E&amp;O or other policies,
                we’ll ensure you get the best product(s) for your risk profile
                by offering a smooth underwriting application and efficient
                purchasing process.
              </div>
            }
          />
        </div>
      </div>
    </div>
  );
};

export default ProductCards;
