import React from 'react';
import Slider from '../../components/Slider';
import marketWatch from '../../assets/images/news/marketwatch.svg';
import ambtv from '../../assets/images/news/ambtv.png';
import coinCover from '../../assets/images/news/coinCover.svg';

const NEWS = [
  {
    logo: coinCover,
    logoStyle: { background: '#ddd' },
    title: 'What is Crypto Insurance? ...',
    source: 'www.coincover.com',
    path: 'https://www.coincover.com/post/what-is-crypto-insurance',
    description:
      'Insurance for cryptocurrency, whether that’s Bitcoin, Ethereum or one of the many other cryptocurrencies available is in demand. With crypto theft on the rise, it is easy to understand...',
  },
  {
    logo: ambtv,
    logoStyle: { background: 'rgb(146,167,195)' },
    title: 'Crypto Insurance Targets New Risks ...',
    source: 'www.ambest.com',
    description:
      'Edin Imsirovic of AM Best and a panel of professionals working at the intersection of insurance and crypto explore the rapidly evolving role of blockchain-enabled insurance',

    path: 'https://www.ambest.com/video/video.aspx?s=1&rc=cryptodefi123',
  },
  {
    logo: marketWatch,

    logoStyle: { background: '#010203' },
    title: 'Crypto insurance is nearly nonexistent ...',
    source: 'www.marketwatch.com',
    descriptionStyle: { paddingBottom: 16 },
    description:
      'Your cash and stocks are covered by insurance. Digital currencies don’t enjoy the same level of protection.',
    path:
      'https://www-marketwatch-com.cdn.ampproject.org/c/s/www.marketwatch.com/amp/story/if-your-crypto-is-stolen-youre-mostly-on-your-own-these-steps-can-help-prevent-that-from-happening-11670259699',
  },
  // {
  //   logo: insurance,

  //   logoStyle: { background: '#343434' },
  //   title:
  //     'Breach raises $2.5 million in seed funding in oversubscribed round ...',
  //   path:
  //     'https://www.insurancebusinessmag.com/us/news/technology/breach-raises-2-5-million-in-seed-funding-in-oversubscribed-round-255879.aspx',
  // },
  // {
  //   logo: insurance,

  //   logoStyle: { background: '#343434' },
  //   title: 'Breach places industry-first crypto insurance policy ...',
  //   path:
  //     'https://www.insurancebusinessmag.com/us/news/cyber/breach-places-industryfirst-crypto-insurance-policy-245260.aspx',
  // },
];

const NewsItem = props => {
  const {
    logo,
    title,
    logoStyle,
    path,
    source,
    description,
    descriptionStyle,
  } = props;
  return (
    <div className="news-item d-flex flex-column" style={{ height: 'inherit' }}>
      <div
        className="news-logo shadow p-4 d-flex align-items-center justify-content-center"
        style={{ height: 150, ...logoStyle }}
      >
        <img src={logo} alt="..." className="img-fluid" />
      </div>
      <div className="p-3 flex-fill">
        <h3 className="f3 font-weight-bold" stlye={{ lineHeight: 2.5 }}>
          {title}
        </h3>
        <h6>{source}</h6>
        <p className="text-muted f7" style={descriptionStyle}>
          {description}
        </p>
      </div>
      <div className="text-right mr-3 mb-3">
        <a
          className="text-info"
          href={path}
          target="_blank"
          rel="noopener noreferrer"
        >
          Read More
        </a>
      </div>
    </div>
  );
};

const NewsAndPress = () => {
  return (
    <div className="news mt-3 mt-md-5">
      <br />
      <br />
      <div className="container">
        <h4 className="font-weight-bold text-center">
          Breach, In <span className="underlined-curve">The News</span>
        </h4>

        <br />

        <Slider>
          {NEWS.map(item => (
            <NewsItem {...item} key={item.title} />
          ))}
        </Slider>
      </div>
    </div>
  );
};

export default NewsAndPress;
