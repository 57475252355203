import React, { useEffect, useState, Suspense } from 'react';

import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import Header from './Header';
import Footer from './Footer';
import * as RuntimeConfig from '../../runtimeConfig';
const PageWrapper = props => {
  const { isDashboardLive, promoText, hideMenu, children } = props;
  const withPromo = !!promoText;
  const [isProd, setIsProd] = useState(true);

  useEffect(() => {
    (async () => {
      const prodFlag = await RuntimeConfig.read('isProd');
      setIsProd(prodFlag);
    })();

    return () => {
      // this now gets called when the component unmounts, unnecessary in this case, but good to note.
    };
  }, []);

  return (
    <Suspense fallback={<div>Loading...</div>}>
      <div className={withPromo ? 'promo' : ''}>
        {!isProd && (
          <Helmet>
            <meta name="robots" content="noindex" />
          </Helmet>
        )}
        <Header
          isDashboardLive={isDashboardLive}
          promoText={promoText}
          hideMenu={hideMenu}
        />
        <div>{children}</div>
        <Footer />
      </div>
    </Suspense>
  );
};

PageWrapper.propTypes = {
  isDashboardLive: PropTypes.bool,
  children: PropTypes.node,
};
export default PageWrapper;
