import React, { Component, useState } from 'react';
import PropTypes from 'prop-types';
import { handleViewport } from '../hocs';

// handleViewport requires a class Component
// eslint-disable-next-line react/prefer-stateless-function
class FadeGroup extends Component {
  render() {
    const { visible, children, className, dir } = this.props;
    return (
      <div
        className={`${className || ''} ${
          visible
            ? 'card-transition-text-visible'
            : `card-transition-text-${dir}`
        }`}
      >
        {children}
      </div>
    );
  }
}

FadeGroup.propTypes = {
  visible: PropTypes.bool,
  children: PropTypes.node,
  dir: PropTypes.string,
  className: PropTypes.string,
};

const FadeGroupWithViewPort = handleViewport(FadeGroup);
const ViewFadeGroup = props => {
  const [visible, setVisible] = useState(false);
  return (
    <FadeGroupWithViewPort
      visible={visible}
      onEnterViewport={() => {
        setVisible(true);
      }}
      onLeaveViewport={() => {
        setVisible(true);
      }}
      {...props}
    />
  );
};

export default ViewFadeGroup;
