import React, { useEffect, useRef } from 'react';

export const handleViewport = Cmp => props => {
  const elRef = useRef();

  useEffect(() => {
    const onScroll = () => {
      const offset = 0;
      if (!elRef.current) return false;
      const top = elRef.current.getBoundingClientRect().top;
      const inViewPort =
        top + offset >= 0 && top - offset <= window.innerHeight;

      if (inViewPort) {
        props.onEnterViewport();
        setTimeout(() => {
          window.removeEventListener('scroll', onScroll);
        }, 500);
      }
      return inViewPort;
    };

    window.addEventListener('scroll', onScroll);

    return () => {
      window.removeEventListener('scroll', onScroll);
    };
  }, [props]);

  return (
    <div ref={el => (elRef.current = el)}>
      <Cmp {...props} />
    </div>
  );
};
