const COIN_META_DATA = {
  ETH: {
    image: 'ETH.svg',
    full_name: 'Ethereum',
    name: 'ethereum',
    code: 'ETH',
    demoQuantity: '3.7',
  },
  XRP: {
    image: 'XRP.svg',
    full_name: 'Ripple',
    name: 'ripple',
    code: 'XRP',
    demoQuantity: '14600',
  },
  BCH: {
    image: 'BCH.svg',
    full_name: 'Bitcoin Cash',
    name: 'bitcoin-cash',
    code: 'BCH',
    demoQuantity: '20',
  },
  EOS: {
    image: 'EOS.svg',
    full_name: 'EOS',
    name: 'eos',
    code: 'EOS',
    demoQuantity: '200',
  },
  BTC: {
    image: 'BTC.svg',
    full_name: 'Bitcoin',
    name: 'bitcoin',
    code: 'BTC',
    demoQuantity: '0.26',
  },
  ADA: {
    image: 'ADA.svg',
    name: 'cardano',
    code: 'ADA',
    demoQuantity: '10500',
  },
  ALGO: {
    image: 'ALGO.svg',
    name: 'algorand',
    code: 'ALGO',
    demoQuantity: '13500',
  },
  AVAX: {
    image: 'AVAX.svg',
    name: 'avalanche',
    code: 'AVAX',
    demoQuantity: '136',
  },
  BNB: {
    image: 'BNB.svg',
    name: 'binance coin',
    code: 'BNB',
    demoQuantity: '90',
  },
  BUSD: {
    image: 'BUSD.svg',
    name: 'binance usd',
    code: 'BNB',
    demoQuantity: '300000',
  },
  DOGE: {
    image: 'DOGE.svg',
    name: 'dogecoin',
    code: 'DOGE',
    demoQuantity: '85000',
  },
  DOT: {
    image: 'DOT.svg',
    name: 'polkadot',
    code: 'DOT',
    demoQuantity: '550',
  },
  LINK: {
    image: 'LINK.svg',
    name: 'chainlink',
    code: 'LINK',
    demoQuantity: '720',
  },
  LTC: {
    image: 'LTC.svg',
    name: 'litecoin',
    code: 'LTC',
    demoQuantity: '96',
  },
  LUNA: {
    image: 'LUNA.svg',
    name: 'terra',
    code: 'LUNA',
    demoQuantity: '115',
  },
  MATIC: {
    image: 'MATIC.svg',
    name: 'polygon',
    code: 'MATIC',
    demoQuantity: '7500',
  },
  SHIB: {
    image: 'SHIB.svg',
    name: 'shibainu',
    code: 'SHIB',
    demoQuantity: '450000000',
  },
  SOL: {
    image: 'SOL.svg',
    name: 'solana',
    code: 'SOL',
    demoQuantity: '110',
  },
  UNI: {
    image: 'UNI.svg',
    name: 'uniswap',
    code: 'UNI',
    demoQuantity: '1100',
  },
  USDT: {
    image: 'USDT.svg',
    name: 'tether',
    code: 'USDT',
    demoQuantity: '12550',
  },
  USDC: {
    image: 'USDC.svg',
    name: 'usdcoin',
    code: 'USDC',
    demoQuantity: '12550',
  },
  WBTC: {
    image: 'wbtc.svg',
    name: 'wrappedbitcoin',
    code: 'WBTC',
    demoQuantity: '0.26',
  },
};

const SUPPORTED_STATES = ['AZ', 'CA', 'DE', 'IL', 'MA', 'NY'];

const PRIMARY_COIN = {
  name: 'bitcoin',
  code: 'BTC',
};

export { COIN_META_DATA, SUPPORTED_STATES, PRIMARY_COIN };
