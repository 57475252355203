import React from 'react';
import {
  AboutEntry,
  DifferentEntry,
  ProtectedRisksEntry,
  OtherHelpEntry,
} from './questions';
import FaqBody from './faqBody';

const Sections = [
  AboutEntry,
  DifferentEntry,
  ProtectedRisksEntry,
  OtherHelpEntry,
];

const Services = () => {
  return <FaqBody sections={Sections} />;
};

export default Services;
