import React from 'react';

// import sidechannel from '../../assets/images/partners/new/sidechan.png';
// import gw from '../../assets/images/partners/new/gw.svg';
// import gfc from '../../assets/images/partners/new/gfc.svg';
// import nayms from '../../assets/images/partners/new/nayms.svg';
// import pjc from '../../assets/images/partners/new/pjc.svg';
// import efani from '../../assets/images/partners/new/efani.svg';

import alumni from '../../assets/images/investors/alumni.svg';
import roadCapital from '../../assets/images/investors/roadCapital.svg';
import rw3 from '../../assets/images/investors/rw3.svg';
import foundationCapital from '../../assets/images/investors/foundationCapital.svg';
// import republic from '../../assets/images/investors/republic.svg';
import raptor from '../../assets/images/investors/raptor.svg';
import lightshed from '../../assets/images/investors/lightshed.svg';
import gfc from '../../assets/images/investors/gfc.svg';
// import efani from '../../assets/images/investors/efani.svg';
// import nayms from '../../assets/images/investors/nayms.svg';
import b1 from '../../assets/images/investors/b1.svg';
import pjc from '../../assets/images/investors/pjc.svg';
import gw from '../../assets/images/investors/gw.svg';

import ViewFadeGroup from '../../components/Fade/FadeGroup';

function Partners() {
  return (
    <div id="partners" className="partners bg-primary">
      <ViewFadeGroup dir="up">
        <div className="">
          <div className="row justify-content-center">
            <h3 className="text-white font-weight-bold">Our Support System</h3>
          </div>
          <div className="d-flex flex-column align-items-center">
            <div className="row justify-content-center">
              <p className="col-sm-12 col-lg-12 text-white text-center mt-1 opacity-50">
                We are proud to be backed by our amazing investors
              </p>
            </div>
            <hr
              className="hr-white mb-2"
              style={{ borderWidth: 1, minWidth: 500, opacity: 0.5 }}
            />
          </div>

          <div className="row justify-content-center">
            <div className="text-center">
              <img className="m-4" src={rw3} width="100" alt="rw3" />
              <img className="m-4" src={raptor} width="150" alt="raptor" />
              <img
                className="m-4"
                src={lightshed}
                width="150"
                alt="lightshed"
              />

              <img
                className="m-4"
                src={foundationCapital}
                width="120"
                alt="foundationcapital"
              />
              <img className="m-4" src={gfc} width="100" alt="gfc" />
            </div>

            <div className="text-center">
              <img
                className="m-4"
                src={roadCapital}
                width="120"
                alt="roadcapital"
              />
              {/* <img
                className="m-4"
                src={republic}
                width="100"
                alt="republic capital"
              /> */}
              <img className="m-4" src={gw} width="150" alt="goodwater" />
              <img className="m-4" src={b1} width="70" alt="block.one" />
              <img className="m-4" src={alumni} width="150" alt="alumni" />
              <img className="m-4" src={pjc} width="100" alt="pjc" />
            </div>
          </div>
        </div>
      </ViewFadeGroup>
    </div>
  );
}

export default Partners;
