import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Collapse } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';

const CollapseSection = props => {
  const { title, titleId, children } = props;
  const [expand, setCollapse] = useState(false);
  return (
    <div
      className={`pt-4 pb-3 pl-4 pr-4 mt-3 mb-3 overflow rounded-xl ${
        expand ? 'bg-secondary-light' : 'bg-secondary-light2'
      }`}
    >
      <h4
        style={{ fontSize: 18 }}
        className={`d-flex font-weight-semi-bold justify-content-between mouse-pointer ${
          expand ? 'text-primary' : ''
        }`}
        data-action-id={`faq-collapsible-${titleId || title}`}
        onClick={() => setCollapse(!expand)}
      >
        <span className="f6">{title}</span>
        <span>
          <FontAwesomeIcon
            icon={expand ? faChevronUp : faChevronDown}
          ></FontAwesomeIcon>
        </span>
      </h4>
      {expand && <hr />}
      <Collapse in={expand}>
        <div className="overflow" style={{ fontSize: 14 }}>
          {children}
        </div>
      </Collapse>
    </div>
  );
};

CollapseSection.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  children: PropTypes.node,
};

export default CollapseSection;
