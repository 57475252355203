import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { Collapse } from 'react-bootstrap';
import {
  faChevronCircleDown,
  faChevronCircleUp,
} from '@fortawesome/free-solid-svg-icons';

const CollapsePanel = ({ title, children, initOpen }) => {
  const [toggle, setToggle] = useState(!!initOpen);
  return (
    <div className="text-white p-3 p-lg-4 bg-primary rounded-xl mt-3">
      <div
        className="d-flex justify-content-between mouse-pointer"
        onClick={() => setToggle(prev => !prev)}
      >
        <h5>{title}</h5>
        <FontAwesomeIcon
          icon={toggle ? faChevronCircleUp : faChevronCircleDown}
        />
      </div>
      <hr />
      <Collapse in={toggle}>{children}</Collapse>
    </div>
  );
};

export default CollapsePanel;
