import React from 'react';
import { render } from 'react-dom';
import ApolloClient from 'apollo-boost';
import { ApolloProvider } from '@apollo/react-hooks';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ReactHeap from 'reactjs-heap';
import Landing from './pages/Landing';
import * as RuntimeConfig from './runtimeConfig';
import { makeUri } from './utils';
import * as serviceWorker from './serviceWorker';
import './index.scss';

const CONFIG = window.__CONFIG__ || {};
// load runtime config from the server
RuntimeConfig.load(makeUri(window.origin));

if (CONFIG.landing === 'cyber') {
  ReactHeap.initialize('3114319890');
} else {
  // initialise analytics
  RuntimeConfig.read('loadAnalytics')
    .then(async loadAnalytics => {
      if (loadAnalytics) {
        const pk = await RuntimeConfig.read('heapPublicKey');
        ReactHeap.initialize(pk);
      }
    })
    // eslint-disable-next-line no-console
    .catch(err =>
      console.error(`Error initialising analytics: ${err.message}`),
    );
}

const onError = ({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    graphQLErrors.map(({ message, locations, path }) =>
      // eslint-disable-next-line no-console
      console.log(
        `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`,
      ),
    );
  }
  if (networkError) {
    // eslint-disable-next-line no-console
    console.log(`[Network error]: ${networkError}`);
  }
};

const uri = makeUri(window.origin, 'graphql');
const client = new ApolloClient({
  uri,
  onError,
});

const App = () => (
  <ApolloProvider client={client}>
    <Landing />
    <ToastContainer />
  </ApolloProvider>
);

render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
