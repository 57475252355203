import React from 'react';

import { makeDashboardUri } from '../../utils';
import CollapseSection from '../partialComponents/CollapseSection';

const dashboardUri = makeDashboardUri(window.origin);

const Policy = () => {
  return (
    <div>
      <CollapseSection title="What does this policy cover?">
        Crypto Shield+ provides coverage for:
        <ul>
          <li>
            Loss of crypto assets resulting directly from theft of crypto-assets
            being held within a qualified exchange wallet
          </li>
          <li>
            Loss of crypto tied to a qualified exchange’s data breach (includes
            phishing attacks directly tied to a hack or a compromised exchange’s
            communications system) Up to 50% appreciation of your crypto’s value
          </li>
          <li>
            Loss of crypto due to email instructions from an exchange to
            transfer crypto from your exchange wallet into an unknown wallet.
          </li>
        </ul>
      </CollapseSection>
      <CollapseSection title="Are third party wallets covered under the Crypto Shield product?">
        No, at this time our product only covers crypto that is custodied in an
        Exchange wallet.
      </CollapseSection>
      <CollapseSection title="How much coverage should I purchase?">
        We recommend that you purchase coverage for the current value of your
        crypto, but ultimately this is a personal decision. Our Crypto Shield+
        product provides coverage for up to a 50% appreciation of your crypto
        value. We make it easy to keep your coverage up to date as you buy and
        sell crypto through our proprietary crypto insurance platform.
      </CollapseSection>
      <CollapseSection title="How long is the policy term?">
        The policy will cover you for 12 months.
      </CollapseSection>
      <CollapseSection title="Can I cancel my policy at any time?">
        Yes, although we’d hate to see you go, you are able to cancel your
        policy at any time by logging into your{' '}
        <a
          className="text-primary"
          href={dashboardUri}
          target="_blank"
          rel="noopener noreferrer"
        >
          Breach account
        </a>
      </CollapseSection>

      <CollapseSection title="How many coins can I cover at once?">
        You are able to cover all of your coins that are supported on the
        platform. There are no limits on how many types of coins you can have
        covered under your policy at any given time. As you acquire additional
        coins, you are able to come back to your policy to add the coins and the
        amount of coverage you want for the coins.
      </CollapseSection>

      <CollapseSection title="What happens if my crypto assets increase in value?">
        As your asset values increase, you are able to come back to your policy
        to increase the amount of coverage you want for the coins. At time of
        purchase, you are able to preemptively purchase up to 150% of your
        coins’ current value if you believe they will rise, with our Crypto
        Shield+ product.
      </CollapseSection>

      <CollapseSection title="Is there a waiting period for my policy?">
        There is a three day waiting period. Your policy becomes effective 3
        days after you purchase. The waiting period will also apply to midterm
        policy changes.
      </CollapseSection>

      <CollapseSection title="Where can I find my policy?">
        You can log into your Breach account at any time to find the details of
        your policy, make changes, and submit a claim.
      </CollapseSection>

      <CollapseSection title="How often can I make changes to my policy?">
        You are able to make changes to your policy anytime during the term of
        your policy. All policy changes are subject to a 3 day waiting period.
      </CollapseSection>
      <CollapseSection title="Will my crypto be covered if the exchange goes bankrupt?">
        Our policy provides coverage protection to policyholders in the event of
        an exchange bankruptcy if it was in result of a hack or theft event. In
        the event the exchange goes bankrupt due to market depreciation or
        business mismanagement, our policy would not cover that loss.
        <br />
        <br />
        For further questions, send us a chat or email us at&nbsp;
        <a
          className="text-primary underline-hover"
          href="mailto:care@breachinsured.com"
          target="_blank"
          rel="noopener noreferrer"
        >
          care@breachinsured.com!
        </a>
      </CollapseSection>
    </div>
  );
};

export default Policy;
