import gql from 'graphql-tag';

export const GET_EXCHANGES = gql`
  query GetExchanges {
    exchanges {
      id
      name
      displayName
      active
      assets {
        id
        code
        name
        fullName
        entity {
          name
        }
        quote {
          quoteItems {
            duration {
              period
              unit
            }
            isDefault
            premium
            premiumCurr
          }
        }
      }
    }
  }
`;

export const GET_PROGRAMS = gql`
  query GetPrograms($programId: String) {
    programs(programId: $programId) {
      id
      name
      defaultCurrency {
        code
      }
      maxCover
      minCover
      minCoverIncrement
      changeThresholdPercent
      regions {
        country
        states
      }
    }
  }
`;
