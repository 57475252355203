import React from 'react';
import FaqBody from './faqBody';
import {
  AboutEntry,
  DifferentInstitutionsEntry,
  WhyCryptoShieldProEntry,
  OtherHelpEntry,
} from './questions';

const Sections = [
  AboutEntry,
  DifferentInstitutionsEntry,
  WhyCryptoShieldProEntry,
  OtherHelpEntry,
];

const Institutions = () => {
  return <FaqBody sections={Sections} />;
};

export default Institutions;
