import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { handleViewport } from '../hocs';

// handleViewport requires a class Component
// eslint-disable-next-line react/prefer-stateless-function
class FadeImage extends Component {
  render() {
    const { visible, src, className, dir, original, width, style } = this.props;
    let imageStyle = '';
    if (visible) {
      if (original) {
        imageStyle = 'card-transition-img-visible-original';
      } else {
        imageStyle = 'card-transition-img-visible';
      }
    } else {
      imageStyle = `card-transition-img-${dir}`;
    }

    return (
      <img
        src={src}
        alt=""
        className={`${className} ${imageStyle}`}
        width={width}
        style={style}
      />
    );
  }
}

FadeImage.propTypes = {
  visible: PropTypes.bool,
  original: PropTypes.bool,
  dir: PropTypes.string,
  className: PropTypes.string,
  src: PropTypes.string,
  width: PropTypes.string,
  style: PropTypes.object,
};

export default handleViewport(FadeImage);
