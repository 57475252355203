import React from 'react';
import FaqBody from './faqBody';
import {
  AboutEntry,
  DifferentSMBEntry,
  OtherHelpEntry,
  WhereEntry,
} from './questions';

const Sections = [AboutEntry, DifferentSMBEntry, OtherHelpEntry, WhereEntry];

const Smbs = () => {
  return <FaqBody sections={Sections} />;
};

export default Smbs;
