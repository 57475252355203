import gql from 'graphql-tag';

export const REGISTER_BROKER = gql`
  mutation RegisterBroker($email: String!) {
    registerBroker(email: $email) {
      success
      errMsg
    }
  }
`;
